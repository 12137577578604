export const testValidField = (value, required=false) => {
  let valid = true;
  if(required){
    if(typeof value === "undefined"){
      valid = false;
    }else if(value === null){
      valid = false;
    }else if(value.length === 0){
      valid = false;
    }
  }
  return valid;
}

export const formatDate = (date = null) => {
  if(!date){
    date = new Date();
  }else{
    date = new Date(date);
  }
  date = date.toISOString();
  date = date.split(".")[0];
  return date;
}

export const getFormattedDate = (date = Date.now(), wYear = true) => {
  date = new Date(date);
  var dd = ("0" + String(date.getDate())).slice(-2);
  var mm = ("0" + String(date.getMonth() + 1)).slice(-2);
  var yyyy = date.getFullYear();
  return dd+"/"+mm+(wYear ? ("/"+yyyy) : "");
}
export const addMonths = (date = Date.now(), num) => {
  date = new Date(date);
  date.setMonth(date.getMonth()+Math.floor(num));
  let diff = num - Math.floor(num);
  if(diff > 0){
    diff = 30 * diff;
    date.setDate(date.getDate()+diff);
  }
  return date;
}