import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from "./Icon.js";

const Wrapper = styled.div`
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }
`;
const Container = styled.div`
  position: relative;
  display: block;
  width: ${props => props.width};
  max-width: 100%;
  display: flex;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Clear = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: calc(${props => props.height} - 16px);
  height: calc(${props => props.height} - 16px);
  background-color: lilac;
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  /* box-shadow: 0px 1px 2px #687FE239; */

  &:hover{
    box-shadow: inset 0px 1px 2px #687FE239;
`;
const InputElement = styled.input`
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;

  padding: 0px ${props => props.clearable ? 35 : 10}px 0px 10px;
  height: ${props => props.height};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  border-radius: 4px;

  ${props => props.variant === "default" ? `
    background-color: ` + (props.disabled ? "lilac" : "#ffffff") + `;
    color: ` + (props.disabled ? "#30303054" : "black") + `;
    border: 1px solid #CBCBCB;
    box-shadow: inset 0px 1px 2px #687FE262;

    ::placeholder {
      color: #dedede;
      opacity: 1;
    }

    :-webkit-autofill {
      ${props => props.disabled ? `
        box-shadow: inset 0 0 0px 9999px #F2F4FD;
      ` : `
        box-shadow: inset 0 0 0px 9999px #ffffff;
      ` };
    }
  ` : props.variant === "charcoal" ? `
      background-color: ` + (props.disabled ? "#F2F4FD" : "#455262") + `;
      color: ` + (props.disabled ? "#30303054" : "#FFFFFF") + `;
      border: 1px solid #202833;
      box-shadow: inset 0px 1px 2px #20283362;

      ::placeholder {
        color: #FFFFFF;
        opacity: 1;
      }

      :-webkit-autofill {
        ${props => props.disabled ? `
          box-shadow: inset 0 0 0px 9999px #F2F4FD;
        ` : `
          box-shadow: inset 0 0 0px 9999px #ffffff;
        ` };
      }
  ` : null };

`;


const Control = styled.div`
  position: relative;
  overflow: hidden;

  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  ${props => props.variant === "default" ? `
    border: 1px solid #CBCBCB;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px #687FE239;




    &:hover{
      box-shadow: inset 0px 1px 2px #687FE239;
      &::after{
        background-color: lightblue0A;
      }
    }
    &:active{
      &::after{
        background-color: lightblue20;
      }
    }

    ` + (props.pressed ? `
      box-shadow: inset 0px 1px 2px #687FE239;
      &::after{
        background-color: lightblue0A;
      }
    ` : null) + `
  ` : props.variant === "charcoal" ? `
    border: 1px solid #202833;
    background-color: #455262;
    box-shadow: 0px 1px 2px #20283362;


    &:hover{
      box-shadow: inset 0px 1px 2px #20283362;
      &::after{
        background-color: lightblue1A;
      }
    }
    &:active{
      &::after{
        background-color: lightblue20;
      }
    }

    ` + (props.pressed ? `
      box-shadow: inset 0px 1px 2px #20283362;
      &::after{
        background-color: lightblue1A;
      }
    ` : null) + `
  ` : null };

  height: ${props => props.height};
  flex-basis: ${props => props.height};
  margin-left: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`;

const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 300;
`;



const Input = ({
  variant = "default",
  label,
  placeholder,
  value = "",
  type = "text",
  onChange,
  width = "260px",
  height = "34px",
  disabled = false,
  required = false,
  toggleVisibility = false,
  copyable = false,
  clearable = false,
  onClear
}) => {
  const [visible, setVisible] = useState(false);

  let iconColor = "black";
  switch (variant) {
    case "charcoal":
      iconColor = "#FFFFFF";
      break;
    default:
      break;
  }

  return (
    <Wrapper>
      {label ?
        <Label>
          {label} {required ? "*" : ""}
        </Label>
      : null}
      <Container
        width={width}
      >
        <InputContainer>
          <InputElement
            placeholder={placeholder}
            clearable={clearable}
            variant={variant}
            height={height}
            type={type !== "password" ? type : (visible ? "text" : "password")}
            disabled={disabled}
            onChange={(e) => {
              if(onChange){onChange(e.target.value)};
            }}
            value={value}
            required={required}
          />
          {clearable && value !== "" ?
            <Clear height={height} onClick={onClear}>
              <Icon size="14px" name={"x"} color={iconColor}/>
            </Clear>
          : null }
        </InputContainer>
        {toggleVisibility ?
          <Control height={height} variant={variant} pressed={visible} onClick={() => setVisible(!visible)}>
            <Icon size="16px" name={visible ? "eye-open" : "eye-close"} color={iconColor}/>
          </Control>
        : null}
        {copyable ?
          <Control height={height} variant={variant} onClick={() => navigator.clipboard.writeText(value)}>
            <Icon size="16px" name={"copy"} color={iconColor}/>
          </Control>
        : null}
      </Container>
    </Wrapper>
  )
}

export default Input;
