import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const PathsSummerCamp = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Summer Camp - Percorsi" : "xxx"}
        languages={{"it": "paths/dressage-basic"}}
      />
      <Section variant="primary" image={"/photos/headers/percorso-summer-camp.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Percorso</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Summer Camp" : "xxx"}</Typo>
            <Typo type="p" align="center" color="#ffffff">Porta i tuoi bambini al nostro Summer Camp!</Typo>
            <Typo type="p" align="center" color="#ffffff">Si divertiranno e impareranno, stando all’aria aperta, a conoscere i cavalli e il lavoro di squadra, parteciperanno a laboratori e giochi d’acqua, e faranno tante nuove amicizie ed esperienze!</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            <Typo type="h3">Summer Camp a cura di:</Typo>
            <Typo type="p">
              <ul>
                <li>Martina Passanante, tecnico di 3 livello della scuola di Equitazione Etica.</li>
                <li>Alessia Carbonin, insegnante di scuola primaria.</li>
              </ul>
            </Typo>            
{/*         
            <hr/>

            <Typo type="h3">xxx</Typo>
            <Typo type="p">
              <ul>
                <li>xxx</li>
              </ul>
            </Typo>         */}

            <hr/>

            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Cosa faremo durante il Summer Camp?</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Lezione da terra e in sella.</li>
                  <li>Cura dei cavalli.</li>
                  <li>Confronto di gruppo.</li>
                  <li>Laboratori.</li>
                  <li>Possibilità di aiuto compiti.</li>
                  <li>Giochi d'acqua.</li>
                </ul>
              </Typo> 
            </Box>
            
            <hr/>

            <Typo type="h3">Quanto costa il Summer Camp?</Typo>
            <Typo type="p">
              <ul>
                <li>Orario intero (8:00-16:00): €150 a settimana.</li>
                <li>Orario ridotto (8:00-12:00): €100 a settimana.</li>
              </ul>
            </Typo>
            <Typo type="p">Contattaci per conoscere gli sconti per fratelli, amici o per l'iscrizione a più settimane!</Typo>
            <Typo type="p">Se vuoi far partecipare i tuoi bambini al campus ma non sono tesserati, puoi accedere alla tessera associativa e assicurativa agevolata!</Typo>

            <Typo type="p">Pranzo e merende vanno portate al sacco da casa.</Typo>
            

          </Col>
          <Col widthmd={4}>

            <Box variant="primary"> 
              <Typo type="h3" color="#ffffff" align="center">Calendario</Typo>
              {/* <Typo type="h0" color="#ffffff" align="center">Prossima data d'inizio</Typo>
              <Typo type="h3" color="#ffffff" align="center">17 marzo 2024</Typo> */}

              <Typo type="h0" color="#ffffff" align="center">Date 2024</Typo>
              <Typo type="p" color="#ffffff" align="center">
                <b>1° sett. </b> - dal 17 al 21 giugno<br/>
                <b>2° sett. </b> - dal 24 al 28 giugno<br/>
                <b>3° sett. </b> - dal 1 al 5 luglio<br/>
                <b>4° sett. </b> - dal 8 al 12 luglio<br/>
                <b>5° sett. </b> - dal 15 al 19 luglio<br/>
                <b>6° sett. </b> - dal 22 al 26 luglio<br/>
                <b>7° sett. </b> - dal 29 luglio al 2 agosto<br/>
                <b>8° sett. </b> - dal 5 al 9 agosto<br/>
                <b>9° sett. </b> - dal 26 al 30 agosto<br/>
              </Typo>

              <Typo type="h0" color="#ffffff" align="center">Iscrizione</Typo>
              <Typo type="p" color="#ffffff" align="center">Tramite Whatsapp<br/>al n° <a href="https://wa.me/393461330641" target="_blank" rel="noreferrer">+39 346 1330641</a><br/>entro mercoledì<br/>della settimana precedente</Typo>
              
              <Typo type="h0" color="#ffffff" align="center">Orari</Typo>
              <Typo type="note" color="#ffffff" align="center">Dal lunedì al venerdì<br/>dalle ore 8:00 alle ore 16:00</Typo>             
              <Typo type="note" color="#ffffff" align="center">Possibilità orario ridotto:<br/>dalle ore 8:00 alle ore 12:00</Typo>             
              <Typo type="note" color="#ffffff" align="center">Possibilità di anticipare l'entrata<br/>in caso di necessità</Typo>             
              
            </Box>
            <img src="/photos/thumbnails/percorso-summer-camp.jpg" alt="Summer Camp" width="100%" />
            <Box variant="light">              
              <Typo type="h4" align="center">Prerequisiti per partecipare</Typo>
              <Typo type="note" align="center">Il Summer Camp è aperto a tutti i bambini e ragazzi!</Typo>             
            </Box>      
              
            
          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default PathsSummerCamp;
