import React, {useEffect} from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";

import Topbar from './Topbar';
import Footer from './Footer';

// import {LAYOUT_SM, LAYOUT_XS } from '../../config';

const Wrapper = styled.div`
  width: 100vw;
  padding-top: 80px;
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = ({ layout, children }) => {  

  return (
    <Wrapper>
      <ScrollToTop />
      <Topbar 
        layout={layout} 
      />
      {children}
      {/* <Showcase
        list={horses}
        rows={1}
        cols={layout === LAYOUT_XS ? 4 : layout === LAYOUT_SM ? 8 : 12}
      /> */}
      <Footer />
    </Wrapper>
  )
}

export default Layout
