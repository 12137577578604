import React, {useState, useEffect, useCallback} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from './../layout/Layout';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card';
import Loading from '../../common/components/Loading';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
// import Checkbox from '../../common/components/Checkbox';
import Textarea from '../../common/components/Textarea';
import Datepicker from '../../common/components/Datepicker';
import Success from '../../common/components/Success';
import Table from '../../common/components/Table';

import {getFormattedDate} from '../../helpers';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-family: "Young Serif", sans-serif;
  font-size: 30px;
  color: #5E0B15;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-bottom: 5px;
`;
const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;

const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *{
    margin-left: 5px;
  }
`;

const HorsesSingle = ({layout}) => {
  const {slug} = useParams();
  const [item, setItem] = useState();
  const [hoovesTrims, setHoovesTrims] = useState([]);
  const [dentists, setDentists] = useState([]);
  const [tempHoovesTrim, setTempHoovesTrim] = useState({});
  const [tempDentist, setTempDentist] = useState({});

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    // if(!item.name || item.item.length === 0){
    //   valid = false;
    // }
    setDisabled(!valid);
  }, [item]);


  const getItem = useCallback(() => {
    DatabaseService.getHorse(slug).then((response) => {
      if(response.success){
        setItem(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);


  const getHoovesTrims = useCallback(() => {
    DatabaseService.getHoovesTrim(slug).then((response) => {
      if(response.success){
        setHoovesTrims(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);

  const getDentists = useCallback(() => {
    DatabaseService.getDentists(slug).then((response) => {
      if(response.success){
        setDentists(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);

  const updateItem = useCallback(() => {
    setErrorMessage("");
    DatabaseService.updateHorse(slug, item).then((response) => {
      if(response.success){
        setSuccessMessage("Dati salvati correttamente!");
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, item]);

  const addHoovesTrim = useCallback(() => {
    setErrorMessage("");
    DatabaseService.addHoovesTrim(slug, tempHoovesTrim).then((response) => {
      if(response.success){
        getHoovesTrims(slug);
        setTempHoovesTrim({});
        let tempItem = {...item};
        console.log(!tempItem.lastHoovesTrim);
        console.log(tempHoovesTrim.date);
        console.log(tempItem.lastHoovesTrim.seconds);
        console.log(tempHoovesTrim.date > tempItem.lastHoovesTrim.seconds);
        if(!tempItem.lastHoovesTrim || tempHoovesTrim.date > tempItem.lastHoovesTrim.seconds){
          tempItem.lastHoovesTrim = tempHoovesTrim.date;
          console.log("aggiorno");
          console.log(tempHoovesTrim.date);
        }
        DatabaseService.updateHorse(slug, tempItem).then((response) => {
          if(response.success){
            setSuccessMessage("Dati salvati correttamente!");
          }else{
            setErrorMessage(response.error);
            console.log(response.error);
          }
        });

      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, tempHoovesTrim, getHoovesTrims, item]);

  const addDentist = useCallback(() => {
    setErrorMessage("");
    DatabaseService.addDentist(slug, tempDentist).then((response) => {
      if(response.success){
        getDentists(slug);
        setTempDentist({});
        let tempItem = {...item};
        if(!tempItem.lastDentist || tempDentist.date.seconds > tempItem.lastDentist.seconds){
          tempItem.lastDentist = tempDentist.date;
        }
        DatabaseService.updateHorse(slug, tempItem).then((response) => {
          if(response.success){
            setSuccessMessage("Dati salvati correttamente!");
          }else{
            setErrorMessage(response.error);
            console.log(response.error);
          }
        });
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, tempDentist, getDentists, item]);

  const deleteHoovesTrim = useCallback((subitem) => {
    DatabaseService.deleteHoovesTrim(slug, subitem.slug).then((response) => {
      if(response.success){
        getHoovesTrims(slug);
      }else{
        console.log(response.error);
      }
    });
  }, [slug, getHoovesTrims]);

  const deleteDentist = useCallback((subitem) => {
    DatabaseService.deleteDentist(slug, subitem.slug).then((response) => {
      if(response.success){
        getDentists(slug);
      }else{
        console.log(response.error);
      }
    });
  }, [slug, getDentists]);

  useEffect(() => {
    getItem();
    getHoovesTrims(slug);
    getDentists(slug);
  }, [slug, getItem, getHoovesTrims, getDentists]);

  if(item){
    return (
      <Layout layout={layout}>
        <Container>
          
          <Header>
            <Title>Cavalli: {item.name}</Title>    
          </Header>

          <Row>
            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Informazioni Anagrafiche</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="6">
                    <Input
                      label="Nome"
                      width="100%"
                      value={item.name}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.name = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Nome sul documento"
                      width="100%"
                      value={item.docname}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.docname = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Specie"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: null},
                        {name: "Cavallo", value: "Cavallo"},
                        {name: "Asino", value: "Asino"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.species}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.species = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Razza"
                      width="100%"
                      value={item.breed}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.breed = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Sesso"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: null},
                        {name: "Femmina", value: "Femmina"},
                        {name: "Castrone", value: "Castrone"},
                        {name: "Stallone", value: "Stallone"},
                        {name: "Maschio", value: "Maschio"}
                      ]}
                      selected={item.sex}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.sex = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Mantello"
                      width="100%"
                      value={item.coat}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.coat = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Input
                      label="Data di nascita"
                      width="100%"
                      value={item.birthday}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.birthday = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Input
                      label="Luogo di nascita"
                      width="100%"
                      value={item.birthplace}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.birthplace = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Select
                      label="Deceduto"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isDeceased}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isDeceased = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Tipo libretto"
                      width="100%"
                      value={item.doctype}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.doctype = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="N° Libretto"
                      width="100%"
                      value={item.docnumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.docnumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="N° Microchip"
                      width="100%"
                      value={item.chipnumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.chipnumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="UELN"
                      width="100%"
                      value={item.uelnnumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.uelnnumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  {/* <Col widthmd="6">
                    <Checkbox
                      label="Emergency"
                      selected={emergency}
                      onChange={(value) => setEmergency(value)}
                    />
                  </Col> */}
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Stato burocratico</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  
                  <Col widthmd="3">
                    <Select
                      label="In struttura"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isHere}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isHere = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Iscritto in BDN"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isInBDN}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isInBDN = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="DPA"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isDPA}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isDPA = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Decesso dichiarato regolarmente"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isDeceasedReported}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isDeceasedReported = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Libretto in scuderia"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.hasOriginalDocs}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.hasOriginalDocs = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Stallato correttamente in BDN"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isCorrectBarncodeInBDN}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isCorrectBarncodeInBDN = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Input
                      label="Codice Stalla in BDN (se non corretto)"
                      width="100%"
                      value={item.barncode}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.barncode = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="12">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.statusNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.statusNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Proprietario</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="3">
                    <Select
                      label="Intestato correttamente"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isCorrectOwner}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isCorrectOwner = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Input
                      label="Nominativo"
                      width="100%"
                      value={item.ownerName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.ownerName = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Input
                      label="Telefono"
                      width="100%"
                      value={item.ownerPhone}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.ownerPhone = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Textarea
                      label="Indirizzo"
                      width="100%"
                      value={item.ownerAddress}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.ownerAddress = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="12">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.ownerNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.ownerNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Salute</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="12">
                    lista
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Pareggi</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="3">
                    <Input
                      label="Intervallo raccomandato (mesi)"
                      width="100%"
                      value={item.hoovesTrimInterval}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.hoovesTrimInterval = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="9">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.hoovesTrimNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.hoovesTrimNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col widthmd="12">
                    {hoovesTrims && hoovesTrims.length > 0 ? 
                      <>
                        <Table
                          columns={[
                            "Date",
                            "Notes",
                            ""
                          ]}
                          values={
                            hoovesTrims.map(subitem => ({
                              values: [                          
                                getFormattedDate(subitem.date.seconds*1000),
                                subitem.notes,
                                <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                  <Button size={"small"} onClick={() => deleteHoovesTrim(subitem)}>Elimina</Button>
                                </TableActions>
                              ]
                            }))
                          }
                        />        
                      </>
                    : null}
                  </Col>
                </Row>
                <Row>
                  <Col widthmd="3">
                    <Datepicker
                      label={"Data"}
                      width={"100%"}
                      value={tempHoovesTrim.date}
                      onChange={(val) => {
                        let tempItem = {...tempHoovesTrim};
                        tempItem.date = val;
                        setTempHoovesTrim(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={tempHoovesTrim.notes}
                      onChange={(val) => {
                        let tempItem = {...tempHoovesTrim};
                        tempItem.notes = val;
                        setTempHoovesTrim(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <div style={{"float": "right"}}><Button onClick={() => addHoovesTrim()} disabled={disabled}>Aggiungi</Button></div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Dentista</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="3">
                    <Input
                      label="Intervallo raccomandato (mesi)"
                      width="100%"
                      value={item.dentistInterval}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.dentistInterval = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="9">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.dentistNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.dentistNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col widthmd="12">
                    {dentists && dentists.length > 0 ? 
                      <>
                        <Table
                          columns={[
                            "Date",
                            "Notes",
                            ""
                          ]}
                          values={
                            dentists.map(subitem => ({
                              values: [                          
                                getFormattedDate(subitem.date.seconds*1000),
                                subitem.notes,
                                <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                  <Button size={"small"} onClick={() => deleteDentist(subitem)}>Elimina</Button>
                                </TableActions>
                              ]
                            }))
                          }
                        />        
                      </>
                    : null}
                  </Col>
                </Row>
                <Row>
                  <Col widthmd="3">
                    <Datepicker
                      label={"Data"}
                      width={"100%"}
                      value={tempDentist.date}
                      onChange={(val) => {
                        let tempItem = {...tempDentist};
                        tempItem.date = val;
                        setTempDentist(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={tempDentist.notes}
                      onChange={(val) => {
                        let tempItem = {...tempDentist};
                        tempItem.notes = val;
                        setTempDentist(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <div style={{"float": "right"}}><Button onClick={() => addDentist()} disabled={disabled}>Aggiungi</Button></div>
                  </Col>
                </Row>
              </Card>
            </Col>
            
          </Row>
          
          <ErrorMessage>
            <br/>{errorMessage}<br/>
          </ErrorMessage>

          <div style={{"float": "right"}}><Button onClick={() => updateItem()} disabled={disabled}>Salva</Button></div>
          <Link to={"/admin/horses"}><Button variant={"default-o"}>Indietro</Button></Link>
          

        </Container>

        {successMessage ?
          <Success fnClose={() =>setSuccessMessage(false)} message={successMessage} />
        : null}

      </Layout>
    )
  }else{
    return <Loading />;
  }
}

export default HorsesSingle;
