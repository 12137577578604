import {useState, useCallback, useEffect, useContext} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import {UserContext} from './contexts/UserContext';
// import { getAnalytics } from "firebase/analytics";

//Frontend
import Home from "./frontend/pages/Home";
import About from "./frontend/pages/About";
import Me from "./frontend/pages/Me";
import Collabs from "./frontend/pages/Collabs";
import Reviews from "./frontend/pages/Reviews";
import Paths from "./frontend/pages/Paths";
import PathsMedication from "./frontend/pages/PathsMedication";
import PathsTrailerLoading from "./frontend/pages/PathsTrailerLoading";
import PathsDressageBasic from "./frontend/pages/PathsDressageBasic";
import PathsOutdoorExcursion from "./frontend/pages/PathsOutdoorExcursion";
import PathsMockExcursion from "./frontend/pages/PathsMockExcursion";
import PathsSummerCamp from "./frontend/pages/PathsSummerCamp";
import Activities from "./frontend/pages/Activities";
// import Disciplines from "./frontend/pages/Disciplines";
import Services from "./frontend/pages/Services";
import ServicesConsulting from "./frontend/pages/ServicesConsulting";
import ServicesHoofCare from "./frontend/pages/ServicesHoofCare";
import ServicesIntroductions from "./frontend/pages/ServicesIntroductions";
import ServicesEthicalHorsemanship from "./frontend/pages/ServicesEthicalHorsemanship";
import Nuovavita from "./frontend/pages/Nuovavita";
import NuovavitaBoarding from "./frontend/pages/NuovavitaBoarding";
import NuovavitaHorseLife from "./frontend/pages/NuovavitaHorseLife";
import NuovavitaLessons from "./frontend/pages/NuovavitaLessons";
import NuovavitaMembership from "./frontend/pages/NuovavitaMembership";
// import BlogList from "./frontend/pages/BlogList";
// import BlogSingle from "./frontend/pages/BlogSingle";
import Contacts from "./frontend/pages/Contacts";

//Authentication
// import Registration from "./backend/pages/Registration";
import Login from "./backend/pages/Login";

//Backend
import AdminDashboard from './backend/pages/Dashboard';
import AdminHorsesList from './backend/pages/HorsesList';
import AdminHorsesSingle from './backend/pages/HorsesSingle';
import AdminMembershipsList from './backend/pages/MembershipsList';
import AdminMembershipsSingle from './backend/pages/MembershipsSingle';
// import AdminEventsList from './backend/pages/EventsList';
// import AdminEventsSingle from './backend/pages/EventsSingle';
// import AdminAdoptionApplicationsList from './backend/pages/AdoptionApplicationsList';
// import AdminAdoptionApplicationsSingle from './backend/pages/AdoptionApplicationsSingle';
// import AdminCessionApplicationsList from './backend/pages/CessionApplicationsList';
// import AdminCessionApplicationsSingle from './backend/pages/CessionApplicationsSingle';

//404
import NotFound from "./frontend/pages/NotFound";

import {BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, LAYOUT_LG, LAYOUT_MD, LAYOUT_SM, LAYOUT_XS } from './config';

const RequireAuth = ({children}: {children: JSX.Element}) => {
  const {user} = useContext(UserContext);
  let location = useLocation();
  // console.log(user);
  if (!user) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }
  return (<>{children}</>);
}

const App = () => {
  const [resizeListener, setResizeListener] = useState(false);
  const [layout, setLayout] = useState(null);

  // useEffect(() => {
  //   AnalyticsService.getUrl(src).then((response) => {
  //     if(response.success){
  //       setUrl(response.url);
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, []);

  const handleResize = useCallback(() => {
    setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
  }, []);

  useEffect(() => {
    if(!resizeListener){
      setResizeListener(true);
      window.addEventListener("resize", handleResize, false);
      handleResize();
    }
  }, [handleResize, resizeListener]);

  return (
    <Routes>

    {/*301*/}
    <Route exact path='/' element={<Navigate to={"/it"} />} />

    {/*Frontend*/}
    <Route exact path='/:lang/' element={<Home layout={layout} />} />
    <Route exact path='/:lang/about' element={<About layout={layout} />} />
    <Route exact path='/:lang/about/me' element={<Me layout={layout} />} />
    <Route exact path='/:lang/about/collabs' element={<Collabs layout={layout} />} />
    <Route exact path='/:lang/about/reviews' element={<Reviews layout={layout} />} />
    <Route exact path='/:lang/paths' element={<Paths layout={layout} />} />
    <Route exact path='/:lang/paths/medication' element={<PathsMedication layout={layout} />} />
    <Route exact path='/:lang/paths/trailer-loading' element={<PathsTrailerLoading layout={layout} />} />
    <Route exact path='/:lang/paths/dressage-basic' element={<PathsDressageBasic layout={layout} />} />
    <Route exact path='/:lang/paths/outdoor-excursion' element={<PathsOutdoorExcursion layout={layout} />} />
    <Route exact path='/:lang/paths/mock-excursion' element={<PathsMockExcursion layout={layout} />} />
    <Route exact path='/:lang/paths/summer-camp' element={<PathsSummerCamp layout={layout} />} />
    
    <Route exact path='/:lang/activities' element={<Activities layout={layout} />} />
    {/* <Route exact path='/:lang/activities/paths' element={<Paths layout={layout} />} />
    <Route exact path='/:lang/activities/disciplines' element={<Disciplines layout={layout} />} /> */}
    <Route exact path='/:lang/services' element={<Services layout={layout} />} />
    <Route exact path='/:lang/services/consulting' element={<ServicesConsulting layout={layout} />} />
    <Route exact path='/:lang/services/hoof-care' element={<ServicesHoofCare layout={layout} />} />
    <Route exact path='/:lang/services/introductions' element={<ServicesIntroductions layout={layout} />} />
    <Route exact path='/:lang/services/ethical-horsemanship' element={<ServicesEthicalHorsemanship layout={layout} />} />
    <Route exact path='/:lang/nuova-vita' element={<Nuovavita layout={layout} />} />
    <Route exact path='/:lang/nuova-vita/boarding' element={<NuovavitaBoarding layout={layout} />} />
    <Route exact path='/:lang/nuova-vita/horse-life' element={<NuovavitaHorseLife layout={layout} />} />
    <Route exact path='/:lang/nuova-vita/lessons' element={<NuovavitaLessons layout={layout} />} />
    <Route exact path='/:lang/nuova-vita/membership' element={<NuovavitaMembership layout={layout} />} />
    <Route exact path='/:lang/contacts' element={<Contacts layout={layout} />} />
    {/* <Route exact path='/:lang/chi-siamo' element={<About layout={layout} />} />
    <Route exact path='/:lang/about-us' element={<About layout={layout} />} />
    <Route exact path='/:lang/cavalli' element={<HorsesList layout={layout} />} />
    <Route exact path='/:lang/horses' element={<HorsesList layout={layout} />} />
    <Route exact path='/:lang/cavalli/:slug' element={<HorsesSingle layout={layout} />} />
    <Route exact path='/:lang/horses/:slug' element={<HorsesSingle layout={layout} />} />
    <Route exact path='/:lang/come-aiutarci' element={<HelpUs layout={layout} />} />
    <Route exact path='/:lang/help-us' element={<HelpUs layout={layout} />} />
    <Route exact path='/:lang/eventi' element={<EventsList layout={layout} />} />
    <Route exact path='/:lang/events' element={<EventsList layout={layout} />} />
    <Route exact path='/:lang/eventi/:slug' element={<EventsSingle layout={layout} />} />
    <Route exact path='/:lang/events/:slug' element={<EventsSingle layout={layout} />} />
    <Route exact path='/:lang/blog' element={<BlogList layout={layout} />} />
    <Route exact path='/:lang/blog/:slug' element={<BlogSingle layout={layout} />} />
    <Route exact path='/:lang/contatti' element={<Contacts layout={layout} />} />
    <Route exact path='/:lang/candidatura-adozione' element={<AdoptionApplication layout={layout} />} />
    <Route exact path='/:lang/adoption-application' element={<AdoptionApplication layout={layout} />} />
    <Route exact path='/:lang/candidatura-cessione' element={<CessionApplication layout={layout} />} />
    <Route exact path='/:lang/cession-application' element={<CessionApplication layout={layout} />} /> */}
      



      {/*Authentication*/}
      {/*<Route exact path='/admin/registration' element={<Registration />} />*/}
      <Route exact path='/admin/login' element={<Login />} />

      {/*Backend*/}
      <Route exact path='/admin' element={<RequireAuth><AdminDashboard layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/horses' element={<RequireAuth><AdminHorsesList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/horses/:slug" element={<RequireAuth><AdminHorsesSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/memberships' element={<RequireAuth><AdminMembershipsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/memberships/:slug" element={<RequireAuth><AdminMembershipsSingle layout={layout} /></RequireAuth>} />
      {/* <Route exact path='/admin/events' element={<RequireAuth><AdminEventsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/events/:slug" element={<RequireAuth><AdminEventsSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/adoption-applications' element={<RequireAuth><AdminAdoptionApplicationsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/adoption-applications/:slug" element={<RequireAuth><AdminAdoptionApplicationsSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/cession-applications' element={<RequireAuth><AdminCessionApplicationsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/cession-applications/:slug" element={<RequireAuth><AdminCessionApplicationsSingle layout={layout} /></RequireAuth>} /> */}

      {/*404*/}
      <Route path='/undefined/*' element={<Navigate to={"/it"} />}  />
      <Route path='*' element={<NotFound layout={layout} />} />
    </Routes>
  );
}

export default App;
