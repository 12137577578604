import React from 'react'
import styled from 'styled-components'

const Heading0 = styled.div`
  font-family: 'Montserrat', sans-serif;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.color !== undefined ? props.color : "#333333"};
  text-transform: uppercase;
`;
const Heading1 = styled.h1`
  font-family: 'Young Serif', sans-serif;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 40px;
  @media (min-width: 576px){
    font-size: 65px;
  }
  @media (min-width: 1200px){
    font-size: 70px;
  }
  font-weight: 400;
  color: ${props => props.color !== undefined ? props.color : "#5E0B15"};
  margin-bottom: ${props => props.margin !== undefined ? props.margin : "50px"};
`;
const Heading2 = styled.h2`
  font-family: 'Young Serif', sans-serif;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 30px;
  @media (min-width: 576px){
    font-size: 40px;
  }
  @media (min-width: 1200px){
    font-size: 45px;
  }
  font-weight: 400;
  color: ${props => props.color !== undefined ? props.color : "#5E0B15"};
  margin-bottom: ${props => props.margin !== undefined ? props.margin : "25px"};
`;
const Heading3 = styled.h3`
  font-family: 'Young Serif', sans-serif;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 25px;
  @media (min-width: 576px){
    font-size: 28px;
  }
  @media (min-width: 1200px){
    font-size: 30px;
  }
  font-weight: 400;
  color: ${props => props.color !== undefined ? props.color : "#5E0B15"};
  margin-bottom: ${props => props.margin !== undefined ? props.margin : "25px"};
`;
const Heading4 = styled.h4`
  font-family: 'Young Serif', sans-serif;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 20px;
  font-weight: 400;
  color: ${props => props.color !== undefined ? props.color : "#5E0B15"};
  margin-bottom: ${props => props.margin !== undefined ? props.margin : "25px"};
`;
const Heading5 = styled.h5`
  font-family: 'Montserrat', sans-serif;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 20px;
  color: ${props => props.color !== undefined ? props.color : "#333333"};
  margin-bottom: ${props => props.margin !== undefined ? props.margin : "14px"};
`;
const Heading6 = styled.h6`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 14px;
  color: ${props => props.color !== undefined ? props.color : "#333333"};
  margin-bottom: ${props => props.margin !== undefined ? props.margin : "10px"};
`;
const Paragraph = styled.p`
  color: ${props => props.color !== undefined ? props.color : "#000000"};
  text-align: ${props => props.align !== undefined ? props.align : "left"};
`;
const Note = styled.p`
  font-size: 13px;
  color: ${props => props.color !== undefined ? props.color : "#000000"};
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  // border: 1px solid #f4f4f433;
  // padding: 20px;
  // background: #ffffff44;
  // box-shadow: 0 2px 8px rgba(0,0,0,0.08);
`;

const Typo = ({ type, align, margin, color, children }) => {
  // console.log(type)
  switch (type) {
    case "h0":
      return (
        <Heading0 align={align} margin={margin} color={color}>
          {children}
        </Heading0>
      )
    case "h1":
      return (
        <Heading1 align={align} margin={margin} color={color}>
          {children}
        </Heading1>
      )
    case "h2":
      return (
        <Heading2 align={align} margin={margin} color={color}>
          {children}
        </Heading2>
      )
    case "h3":
      return (
        <Heading3 align={align} margin={margin} color={color}>
          {children}
        </Heading3>
      )
    case "h4":
      return (
        <Heading4 align={align} margin={margin} color={color}>
          {children}
        </Heading4>
      )
    case "h5":
      return (
        <Heading5 align={align} margin={margin} color={color}>
          {children}
        </Heading5>
      )
    case "h6":
      return (
        <Heading6 align={align} margin={margin} color={color}>
          {children}
        </Heading6>
      )
    case "p":
      return (
        <Paragraph align={align} margin={margin} color={color}>
          {children}
        </Paragraph>
      )
    case "note":
      return (
        <Note align={align} margin={margin} color={color}>
          {children}
        </Note>
      )
    default:
      return (
        <>
          {children}
        </>
      )
  }
}


export default Typo
