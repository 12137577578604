import React, {useRef, useState, useEffect, useCallback, 
  // useContext
} from 'react';
// import {OverlayContext} from '../../contexts/OverlayContext';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';
// import MembershipsModalNew from './MembershipsModalNew';

import Layout from '../layout/Layout';
import Filters from '../layout/Filters';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Table from '../../common/components/Table';
import Loading from '../../common/components/Loading';
import Card from '../../common/components/Card';
// import Button from '../../common/components/Button';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
  overflow-x: auto; 

  & a > div {
    color: #000000;
  }
`;
// const TableActions = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;

//   & > *{
//     margin-left: 5px;
//   }
// `;
const NoResult = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-family: "Young Serif", sans-serif;
  font-size: 30px;
  color: #5E0B15;
`;
const Tag = styled.div`
  display: inline-block;
  font-weight: 600;
  border-radius: 20px;
  padding: 0px 5px 1px;
  margin: 2px;
  ${props => props.status === 1 ? `
    color: white;
    background: green;
  ` : props => props.status === -1 ? `
    color: white;
    background: red;
  ` : `
    color: white;
    background: grey;
  `}
  text-transform: uppercase;
`;

const MiniTag = styled.div`
  display: inline-block;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
`;


const MembershipsList = ({layout}) => {
  let scrollRef = useRef(null);
  // const {setOverlay} = useContext(OverlayContext);
  // let navigate = useNavigate();
  const [list, setList] = useState(null);
  const [page, setPage] = useState(0);

  // const [mapSettings, setMapSettings] = useState({
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627
  //   },
  //   zoom: 11
  // });

  // const [showModalNew, toggleModalNew] = useState(false);

  const [filters, setFilters] = useState({
    "year": 2024
  });

  const changeFilters = useCallback((name, value) => {
    setList(null);
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    if(scrollRef && scrollRef.current){
      scrollRef.current.scrollTo(0,0);
    }
    DatabaseService.getMemberships({
      page: page,
      isHere: filters.isHere,
      isInBDN: filters.isInBDN,
      isCorrectOwner: filters.isCorrectOwner,
      isCorrectBarncodeInBDN: filters.isCorrectBarncodeInBDN,
      isDeceased: filters.isDeceased,
      // start: filters.rangeDate.split('|')[0],
      // end: filters.rangeDate.split('|')[1],
      // level: filters.level
    }).then((response) => {
      if(response.success){
        setList(response.data);
      }else{
        console.log(response.error);
      }
    });

  }, [page, filters]);

  useEffect(() => {
    getList();
  }, [getList, page, filters]);

  // const deleteItem = useCallback((item) => {
  //   setList(null);
  //   DatabaseService.deleteMember(item.slug).then((response) => {
  //     if(response.success){
  //       getList();
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, [getList]);


  return (
    <Layout layout={layout}>
      <div ref={scrollRef}>
        <Container>
          <Header>
            <Title>Tesserati {list ? <small>({list.length})</small> : null}</Title>            
            {/* <Button onClick={() => setOverlay(<MembershipsModalNew />)}>Nuovo</Button> */}
          </Header>

          <Row>
            <Col>
              <Card>
                <Row>
                  <Col widthmd="12">
                    <Filters
                      onChange={(...args) => changeFilters(...args)}
                      fields={
                        [
                          {
                            "label": "Anno",
                            "name": "year",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.year,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },{
                                "name": "2024",
                                "value": 2024
                              }
                            ]
                          }
                        ]
                      }
                    />
                    <hr/>
                    {list ?
                      list.length > 0 ?
                        <>
                          <TableWrapper>
                            <Table
                              columns={[
                                "",
                                "Nome",
                                "Cognome",
                                "N° tessera",
                                // ""
                              ]}
                              values={
                                list.map(item => ({
                                  style:{
                                    background: item.hasPaid !== 1 || item.isSubmitted !== 1 || item.hasCard !== 1 ? "#f1f1f199" : "transparent"
                                  },
                                  values: [
                                    <MiniTag>{item.year}</MiniTag>,
                                    <>
                                      {item.givenName}
                                    </>,   
                                    <>
                                      {item.familyName}
                                    </>,   
                                    <>
                                      {item.number}
                                    </>,  
                                    <>
                                    <Tag status={item.hasPaid}>Pagato</Tag>
                                    <Tag status={item.isSubmitted}>Inviato</Tag>
                                    <Tag status={item.hasCard}>Tessera</Tag>
                                    </>,
                                    // <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                    //   <Button size={"small"} onClick={() => deleteItem(item)}>Elimina</Button>
                                    // </TableActions>
                                  ],
                                  link: ({children}) => (<Link to={"/admin/memberships/"+item.slug}>{children}</Link>)
                                }))
                              }
                            />
                          </TableWrapper>
                        </>
                      : <NoResult>There are no results.</NoResult>
                    : <Loading />}
                  </Col>
                </Row>
                {/* {totPages > 1 ?
                  <OzPagination totPages={totPages} currentPage={page} onChange={((val) => setPage(val))} />
                : null } */}
              </Card>
            </Col>
          </Row>

          
        </Container>
      </div>
    </Layout>
  )
}

export default MembershipsList;
