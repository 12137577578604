import React, {Component} from 'react';
import styled from 'styled-components';
// import ReactDOM from 'react-dom';
// import './MyDatePicker.css';

import OzIcon from "./Icon.js";


const Wrapper = styled.div`
  text-align: left;
  user-select: none;

  &, & * {
    box-sizing: border-box;
  }
  input::-webkit-calendar-picker-indicator{
    display: none;
  }
`;
const Container = styled.div`
  position: relative;
  display: block;
  width: ${props => props.width};
  max-width: 100%;
  display: flex;
`;
const InputGroup = styled.div`
  position: relative;
  display: block;
  width: ${props => props.width};
  max-width: 100%;
  display: flex;

  & :not(:last-child) *{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0px;
  }
  & :not(:first-child) *{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  ${props => props.open ? `
    & :first-child *{
      border-bottom-left-radius: 0px !important;
    }
    & :last-child *{
      border-bottom-right-radius: 0px !important;
    }
  ` : null }
`;
const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Clear = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: calc(${props => props.height} - 16px);
  height: calc(${props => props.height} - 16px);
  background-color: ${"#DEDEDE"};
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 3;

  /* box-shadow: 0px 1px 2px #687FE239; */

  &:hover{
    box-shadow: inset 0px 1px 2px #687FE239;
`;
const Input = styled.input`
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;

  padding: 0px 10px;
  height: ${props => props.height};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  border-radius: 4px;

  ${props => props.variant === "default" ? `
    background-color: ` + (props.disabled ? "#F2F4FD" : "#ffffff") + `;
    color: ` + (props.disabled ? "#30303054" : "#000000") + `;
    border: 1px solid #CBCBCB;
    box-shadow: inset 0px 1px 2px #687FE262;

    ::placeholder {
      color: #dedede;
      opacity: 1;
    }

    :-webkit-autofill {
      ${props => props.disabled ? `
        box-shadow: inset 0 0 0px 9999px #F2F4FD;
      ` : `
        box-shadow: inset 0 0 0px 9999px #ffffff;
      ` };
    }
  ` : props.variant === "charcoal" ? `
      background-color: ` + (props.disabled ? "#F2F4FD" : "#455262") + `;
      color: ` + (props.disabled ? "#30303054" : "#FFFFFF") + `;
      border: 1px solid #202833;
      box-shadow: inset 0px 1px 2px #20283362;

      ::placeholder {
        color: #FFFFFF;
        opacity: 1;
      }

      :-webkit-autofill {
        ${props => props.disabled ? `
          box-shadow: inset 0 0 0px 9999px #F2F4FD;
        ` : `
          box-shadow: inset 0 0 0px 9999px #ffffff;
        ` };
      }
  ` : null };

`;

const ClickCatcher = styled.div`
  display: ${props => props.open ? "block" : "none"};
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
const CalendarGroup = styled.div`
  position: absolute;
  left: 0;
  top: ${props => props.top};
  width: ${props => props.width};

  background-color: #ffffff;
  border: 1px solid #CBCBCB;
  box-shadow: 0px 1px 2px #687FE262;

  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  margin-top: -1px;

  padding: 5px 10px;
  z-index: 99999;
  display: flex;
`;
const Calendar = styled.div`
  padding: 10px;
`;
const CalendarPrevButton = styled.div`
  position: absolute;
  top: 120px;
  left: calc(-${props => props.height} / 2);
  display: block;
  width: ${props => props.height};
  height: ${props => props.height};
  background-color: #ffffff;
  border: 1px solid #CBCBCB;
  box-shadow: 0px 1px 2px #687FE262;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;
const CalendarNextButton = styled.div`
  position: absolute;
  top: 120px;
  right: calc(-${props => props.height} / 2);
  display: block;
  width: ${props => props.height};
  height: ${props => props.height};
  background-color: #ffffff;
  border: 1px solid #CBCBCB;
  box-shadow: 0px 1px 2px #687FE262;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;
const CalendarHead = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
`;
const CalendarBody = styled.div`
  float: left;
  width: 100%;
`;

const Weekdays = styled.div`
  position: relative;
  display: block;
  float: left;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
`;

const Weekday = styled.div`
  position: relative;
  display: block;
  float: left;
  box-sizing: border-box;
  width: 14.285%;
  text-align: center;

  font-weight: 600;
  font-size: 9px;
`;
const Days = styled.div`
  position: relative;
  display: block;
  float: left;
  box-sizing: border-box;
  height: 200px;
  width: 100%;
`;
const DayContainer = styled.div`
  position: relative;
  display: block;
  float: left;
  box-sizing: border-box;
  width: 14.285%;
  height: 16.6666%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${props => props.isBetweenSelected && !props.isDisabled && !props.isSelected? `
    &::before{
      display: block;
      content: "";
      position: absolute;
      top: 5px;
      height: 24px;
      width: 100%;
      background: `+"#DEDEDE"+`;
      z-index: 2;
    }
  ` : props.isBetweenSelected && !props.isDisabled && props.isStart? `
    &::after{
      display: block;
      content: "";
      position: absolute;
      top: 5px;
      right: 0px;
      height: 24px;
      width: 50%;
      background: `+"#DEDEDE"+`;
      z-index: 2;
    }
  ` : props.isBetweenSelected && !props.isDisabled && props.isEnd? `
    &::after{
      display: block;
      content: "";
      position: absolute;
      top: 5px;
      left: 0px;
      height: 24px;
      width: 50%;
      background: `+"#DEDEDE"+`;
      z-index: 2;
    }
  ` : null }
`;
const Day = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #444;
  padding-top: 1px;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;

  border: 2px solid #fff;
  &:hover{
    border-color: ${"#a1a1a1"};
    background: #ffffff;
  }


  ${props => props.isDisabled ? `
    pointer-events: none;
    color: #ddd;
  ` : props.isSelected ? `
    color: #fff;
    background: `+"#a1a1a1"+`;
    &:hover{
      border-color: `+"#a1a1a1"+`;
      background: `+"#a1a1a1"+`;
    }
  ` : props.isToday ? `
    color: #fff;
    background: `+"#a1a1a1"+`;
    &:hover{
      border-color: `+"#a1a1a1"+`;
      background: `+"#a1a1a1"+`;
    }
  ` : props.isBetweenSelected ? `
    border-color: transparent;
  ` : null }
    z-index: 3;
`;


const DayNumber = styled.div`
  z-index: 3;
`;


const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 300;
`;


export default class OzDatepicker extends Component {

    state = {
        getMonthDetails: []
    }

    constructor() {
        super();
        let date = new Date();
        let tempYear = date.getFullYear();
        let tempMonth = date.getMonth();
        this.state = {
            year: tempYear,
            month: tempMonth,
            monthDetails: this.getMonthDetails(tempYear, tempMonth),
            rangeStart:  null
        }
    }


    daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    getDayDetails =args=> {
        let date = args.index - args.firstDay;
        let day = args.index%7;
        let prevMonth = args.month-1;
        let prevYear = args.year;
        if(prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays+date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        return {
            date: _date,
            day,
            month,
            timestamp,
            dayString: this.daysMap[day]
        }
    }

    getNumberOfDays =(year, month)=> {
        return 40 - new Date(year, month, 40).getDate();
    }

    getMonthDetails =(year, month)=> {
        let firstDay = (new Date(year, month)).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for(let row=0; row<rows; row++) {
            for(let col=0; col<cols; col++) {
                currentDay = this.getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        return monthArray;
    }

    getDateFromDateString =dateValue=> {
        let dateData = dateValue.split('-').map(d=>parseInt(d, 10));
        if(dateData.length < 3)
            return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return {year, month, date};
    }

    getMonthStr =month=> this.monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromDate = jsdate=> {
        let dateObject = new Date(jsdate);
        let month = dateObject.getMonth()+1;
        let date = dateObject.getDate();
        return dateObject.getFullYear() + '-' + (month < 10 ? '0'+month : month) + '-' + (date < 10 ? '0'+date : date);
    }

    updateDateFromInputStart =(e)=> {
        let dateData = this.getDateFromDateString( e.target.value);
        if(dateData !== null) {
            let selectedDay = new Date(dateData.year, dateData.month-1, dateData.date).getTime();
            if(this.props.type === "range"){
              this.onChange(selectedDay, selectedDay);
              this.setState({
                rangeStart: selectedDay
              });
            }else{
              this.onChange(selectedDay);
            }

            this.setState({
                year: dateData.year,
                month: dateData.month-1,
                monthDetails: this.getMonthDetails(dateData.year, dateData.month-1)
            })
        }
    }

    updateDateFromInputEnd =(e)=> {
        let dateData = this.getDateFromDateString( e.target.value);
        if(dateData !== null) {
            let selectedDay = new Date(dateData.year, dateData.month-1, dateData.date).getTime();
            if(this.state.rangeStart < selectedDay){
              this.onChange(this.state.rangeStart, selectedDay);
            }else{
              this.onChange(selectedDay, this.state.rangeStart);
            }
            this.setState({
                year: dateData.year,
                month: dateData.month-1,
                monthDetails: this.getMonthDetails(dateData.year, dateData.month-1)
            })
        }
    }

    setYear =offset=> {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({
            year,
            monthDetails: this.getMonthDetails(year, month)
        })
    }

    setMonth =offset=> {
        let year = this.state.year;
        let month = this.state.month + offset;
        if(month === -1) {
            month = 11;
            year--;
        } else if(month === 12) {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.getMonthDetails(year, month)
        })
    }

    onChange = (date1=null, date2=null) => {
      // if(date2){
        this.props.onChange(date1 ? new Date(date1) : null, date2 ? new Date(date2) : null);
      // }else{
      //   this.props.onChange(new Date(date1));
      // }
    }

    isSameDay = (date1, date2) => {
      if(date1 instanceof Date && !isNaN(date1) && date2 instanceof Date && !isNaN(date2)){
        return (
          date1.getDate() === date2.getDate() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getFullYear() === date2.getFullYear()
        )
      }else{
        return false;
      }
    }


    render() {
        return (
          <Wrapper>
            {this.props.label ?
              <Label>
                {this.props.label} {this.props.required ? "*" : ""}
              </Label>
            : null}
            <Container
              width={this.props.width ? this.props.width : "260px"}
            >
              <InputGroup width={this.props.width ? this.props.width : "260px"} open={this.state.open}>
                <InputContainer>
                  <Input
                    variant={this.props.variant ? this.props.variant : "default"}
                    width="100%"
                    height={this.props.height ? this.props.height : "34px"}
                    type={"date"}
                    disabled={this.props.disabled}
                    onChange={(e) => this.updateDateFromInputStart(e)}
                    value={this.props.type === "range" ?
                      (this.props.startValue ? this.getDateStringFromDate(this.props.startValue) : false)
                    :
                      (this.props.value ? this.getDateStringFromDate(this.props.value) : false)
                    }
                    onClick={(e)=> {
                      e.preventDefault();
                      this.setState({ open: true })
                    }}
                    required
                  />
                  {(this.props.type !== "range" && this.props.value) || (this.props.type === "range" && this.props.startValue !== false) ?
                    <Clear height={this.props.height ? this.props.height : "34px"} onClick={() => {
                      this.onChange(null, null);
                    }}>
                      <OzIcon size="14px" name={"x"} color={"#000000"}/>
                    </Clear>
                  : null }
                </InputContainer>
                {this.props.type === "range" ?
                  <InputContainer>
                    <Input
                      variant={this.props.variant ? this.props.variant : "default"}
                      width="100%"
                      height={this.props.height ? this.props.height : "34px"}
                      type={"date"}
                      disabled={this.props.disabled}
                      onChange={(e) => this.updateDateFromInputEnd(e)}
                      value={this.props.endValue ? this.getDateStringFromDate(this.props.endValue) : false}
                      onClick={(e)=> {
                        e.preventDefault();
                        this.setState({ open: true })
                      }}
                      required
                    />
                    {this.props.endValue !== false ?
                      <Clear height={this.props.height ? this.props.height : "34px"} onClick={() => {
                        this.onChange(this.props.startValue, null);
                      }}>
                        <OzIcon size="14px" name={"x"} color={"#000000"}/>
                      </Clear>
                    : null }
                  </InputContainer>
                : null }
              </InputGroup>

                {this.state.open ?
                  <CalendarGroup width={this.props.width ? this.props.width : "260px"} top={this.props.height ? this.props.height : "34px"}>

                    {/*<CalendarPrevButton onClick={()=> this.setYear(-1)} height={this.props.height ? this.props.height : "34px"}>
                      <OzIcon name="arrow-left" color="red" />
                    </CalendarPrevButton>*/}
                    <CalendarPrevButton onClick={()=> this.setMonth(-1)} height={this.props.height ? this.props.height : "34px"}>
                      <OzIcon name="arrow-left" size="16px" />
                    </CalendarPrevButton>
                    <CalendarNextButton onClick={()=> this.setMonth(1)} height={this.props.height ? this.props.height : "34px"}>
                      <OzIcon name="arrow-right" size="16px" />
                    </CalendarNextButton>
                    {/*<CalendarNextButton onClick={()=> this.setYear(1)} height={this.props.height ? this.props.height : "34px"}>
                      <OzIcon name="arrow-right" color="red" />
                    </CalendarNextButton>*/}


                    <Calendar>
                      <CalendarHead>
                        {this.getMonthStr(this.state.month)} {this.state.year}
                      </CalendarHead>
                      <CalendarBody>
                      <div className='c-container'>
                        <Weekdays>
                          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d,i)=><Weekday key={i}>{d}</Weekday>)}
                        </Weekdays>
                        <Days>
                          {this.state.monthDetails.map((day, index)=>
                            <DayContainer
                              key={index}
                              isDisabled={day.month !== 0}
                              isSelected={this.isSameDay(new Date(day.timestamp), this.props.value) || this.isSameDay(new Date(day.timestamp), this.props.startValue) || this.isSameDay(new Date(day.timestamp), this.props.endValue)}
                              isBetweenSelected={this.props.startValue <= new Date(day.timestamp) && new Date(day.timestamp) <= this.props.endValue}
                              isStart={this.isSameDay(new Date(day.timestamp), this.props.startValue) && this.props.startValue < this.props.endValue}
                              isEnd={this.isSameDay(new Date(day.timestamp), this.props.endValue) && this.props.startValue < this.props.endValue}
                            >
                              <Day
                                height={this.props.height ? this.props.height : "34px"}
                                isDisabled={day.month !== 0}
                                isToday={this.isSameDay(new Date(day.timestamp), new Date(Date.now()))}
                                isSelected={this.isSameDay(new Date(day.timestamp), this.props.value) || this.isSameDay(new Date(day.timestamp), this.props.startValue) || this.isSameDay(new Date(day.timestamp), this.props.endValue)}
                                isBetweenSelected={this.props.startValue <= new Date(day.timestamp) && new Date(day.timestamp) <= this.props.endValue}

                                onClick={()=> {
                                  if(this.props.type === "range"){
                                    if(!this.state.rangeStart){
                                      this.onChange(day.timestamp, day.timestamp);
                                      this.setState({
                                        rangeStart: day.timestamp
                                      });
                                    }else{
                                      if(this.state.rangeStart < day.timestamp){
                                        this.onChange(this.state.rangeStart, day.timestamp);
                                      }else{
                                        this.onChange(day.timestamp, this.state.rangeStart);
                                      }
                                      this.setState({
                                        rangeStart: null
                                      });
                                    }
                                  }else{
                                    this.onChange(day.timestamp);
                                  }
                                }}
                              >
                                <DayNumber>{day.date}</DayNumber>
                              </Day>
                            </DayContainer>
                          )}
                        </Days>
                      </div>
                      </CalendarBody>
                    </Calendar>
                    {this.props.type === "range" ?
                      <Calendar>
                        <CalendarHead>
                          {this.getMonthStr(this.state.month === 11 ? 0 : this.state.month+1)} {this.state.month === 11 ? this.state.year + 1 : this.state.year}
                        </CalendarHead>
                        <CalendarBody>
                          <div className='c-container'>
                            <Weekdays>
                              {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d,i)=><Weekday key={i}>{d}</Weekday>)}
                            </Weekdays>
                            <Days>
                              {this.getMonthDetails(this.state.month === 11 ? this.state.year + 1 : this.state.year, this.state.month === 11 ? 0 : this.state.month+1).map((day, index)=>
                                <DayContainer
                                  key={index}
                                  isDisabled={day.month !== 0}
                                  isSelected={this.isSameDay(new Date(day.timestamp), this.props.value) || this.isSameDay(new Date(day.timestamp), this.props.startValue) || this.isSameDay(new Date(day.timestamp), this.props.endValue)}
                                  isBetweenSelected={this.props.startValue <= new Date(day.timestamp) && new Date(day.timestamp) <= this.props.endValue}
                                  isStart={this.isSameDay(new Date(day.timestamp), this.props.startValue) && this.props.startValue < this.props.endValue}
                                  isEnd={this.isSameDay(new Date(day.timestamp), this.props.endValue) && this.props.startValue < this.props.endValue}
                                >
                                  <Day
                                    height={this.props.height ? this.props.height : "34px"}
                                    isDisabled={day.month !== 0}
                                    isToday={this.isSameDay(new Date(day.timestamp), new Date(Date.now()))}
                                    isSelected={this.isSameDay(new Date(day.timestamp), this.props.value) || this.isSameDay(new Date(day.timestamp), this.props.startValue) || this.isSameDay(new Date(day.timestamp), this.props.endValue)}
                                    isBetweenSelected={this.props.startValue <= new Date(day.timestamp) && new Date(day.timestamp) <= this.props.endValue}

                                    onClick={()=> {
                                      if(this.props.type === "range"){
                                        if(!this.state.rangeStart){
                                          this.onChange(day.timestamp, day.timestamp);
                                          this.setState({
                                            rangeStart: day.timestamp
                                          });
                                        }else{
                                          if(this.state.rangeStart < day.timestamp){
                                            this.onChange(this.state.rangeStart, day.timestamp);
                                          }else{
                                            this.onChange(day.timestamp, this.state.rangeStart);
                                          }
                                          this.setState({
                                            rangeStart: null
                                          });
                                        }
                                      }else{
                                        this.onChange(day.timestamp);
                                      }
                                    }}
                                  >
                                    <DayNumber>{day.date}</DayNumber>
                                  </Day>
                                </DayContainer>
                              )}
                            </Days>
                          </div>
                        </CalendarBody>
                      </Calendar>
                    : null }
                  </CalendarGroup>
                : null}

            </Container>
            <ClickCatcher open={this.state.open} onClick={() => this.setState({open: false})} />
          </Wrapper>
        )
    }

}
