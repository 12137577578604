import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {UserContextProvider} from './contexts/UserContext';
import {OverlayContextProvider} from './contexts/OverlayContext';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <OverlayContextProvider>
		  <BrowserRouter>
			<App />
		  </BrowserRouter>
	  </OverlayContextProvider>
    </UserContextProvider>
  </React.StrictMode>
);

// ReactDOM.hydrateRoot(document.getElementById('root'), <React.StrictMode>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// </React.StrictMode>);