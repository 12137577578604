import { initializeApp } from "@firebase/app";
import { getFirestore, query, where, orderBy, doc, collection, getDoc, setDoc, addDoc, updateDoc, deleteDoc, getDocs } from "@firebase/firestore";
// import { getFirestore, query, where, orderBy, doc, collection, getDoc, setDoc, updateDoc, getDocs, increment } from "@firebase/firestore";

import {firebaseConfig} from './../config';

class DatabaseService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.db = getFirestore(this.app);
  }

  addLog(func, params){
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("martinapassananteUser"));
      if(user){
        addDoc(collection(this.db, "logs"), {
          user: user,
          function: func,
          params: params
        }).then((response) => {
          // console.log("Scrittura: addAdoptionApplication()");
          let res = {};
          res.success = true;
          resolve(res);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  getHorses(q = null, page = null){
    let composedQuery = query(collection(this.db, "horses"));
    if(q && q.isHere){
      composedQuery = query(composedQuery, where("isHere", "==", q.isHere));
    }
    if(q && q.isInBDN){
      composedQuery = query(composedQuery, where("isInBDN", "==", q.isInBDN));
    }
    if(q && q.isCorrectOwner){
      composedQuery = query(composedQuery, where("isCorrectOwner", "==", q.isCorrectOwner));
    }
    if(q && q.isCorrectBarncodeInBDN){
      composedQuery = query(composedQuery, where("isCorrectBarncodeInBDN", "==", q.isCorrectBarncodeInBDN));
    }
    if(q && q.isDeceased){
      composedQuery = query(composedQuery, where("isDeceased", "==", q.isDeceased));
    }
    // if(q && q.order && q.order !== "random"){
      composedQuery = query(composedQuery, orderBy("docname"));
    // }
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getHorses("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        if(q && q.order === "random"){
          res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()})).sort(() => Math.random() - 0.5);
        }else{
          res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        }
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // getCardsBySet(kSet, kLanguage){
  //   return new Promise((resolve, reject) => {
  //     getDocs(query(query(query(query(collection(this.db, "cards")), where("kSet", "==", kSet)), where("kLanguage", "==", kLanguage)), orderBy("index"))).then((response) => {
  //       console.log("Lettura: getCardsBySet("+kSet+", "+kLanguage+")");
  //       let res = {};
  //       res.success = true;
  //       res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
  //       resolve(res);
  //     }).catch((error) => {
  //       reject(error);
  //     });
  //   });
  // }

  getHorse(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "horses", slug)).then((response) => {
        // console.log("Lettura: getHorse("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addHorse(item){
    return new Promise((resolve, reject) => {
      addDoc(collection(this.db, "horses"), item).then((response) => {
        // console.log("Scrittura: updateHorse("+")");
        // console.log(response);
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateHorse(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "horses", slug), item).then((response) => {
        this.addLog("updateHorse", {"slug":slug, "item":item});
        // console.log("Scrittura: updateHorse("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteHorse(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "horses", slug)).then((response) => {
        this.addLog("deleteHorse", {"slug":slug});
        // console.log("Scrittura: deleteHorse("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addHoovesTrim(horseSlug, item){
    return new Promise((resolve, reject) => {

      console.log(item);
      addDoc(collection(this.db, "horses/"+horseSlug+"/hoovesTrims"), item).then((response) => {
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  getHoovesTrim(horseSlug, page = null){
    let composedQuery = query(collection(this.db, "horses/"+horseSlug+"/hoovesTrims"));
    // composedQuery = query(composedQuery, orderBy("date"));
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log(response.docs);
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  deleteHoovesTrim(horseSlug, slug){
    return new Promise((resolve, reject) => {
      console.log(horseSlug);
      console.log(slug);
      deleteDoc(doc(this.db, "horses/"+horseSlug+"/hoovesTrims", slug)).then((response) => {
        // this.addLog("deleteHoovesTrim", {"slug":slug});
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addDentist(horseSlug, item){
    return new Promise((resolve, reject) => {

      console.log(item);
      addDoc(collection(this.db, "horses/"+horseSlug+"/dentists"), item).then((response) => {
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  getDentists(horseSlug, page = null){
    let composedQuery = query(collection(this.db, "horses/"+horseSlug+"/dentists"));
    // composedQuery = query(composedQuery, orderBy("date"));
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log(response.docs);
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }



  getEvents(q = null, page = null){
    let composedQuery = query(collection(this.db, "events"));
   
    if(q && q.status === "past"){
      composedQuery = query(composedQuery, where("date", "<", new Date()));
    }
    if(q && q.status === "next"){
      composedQuery = query(composedQuery, where("date", ">=", new Date()));
    }
    if(q && q.order === "asc"){
      composedQuery = query(composedQuery, orderBy("date"));
    }
    if(q && q.order === "desc"){
      composedQuery = query(composedQuery, orderBy("date"));
    }
    
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getEvents("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getEvent(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "events", slug)).then((response) => {
        // console.log("Lettura: getEvent("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addEvent(slug, item){
    return new Promise((resolve, reject) => {
      setDoc(doc(this.db, "events", slug), item).then((response) => {
        this.addLog("addEvent", {"slug":slug, "item":item});
        // console.log("Scrittura: addEvent("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateEvent(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "events", slug), item).then((response) => {
        this.addLog("updateEvent", {"slug":slug, "item":item});
        // console.log("Scrittura: updateEvent("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteEvent(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "events", slug)).then((response) => {
        this.addLog("deleteEvent", {"slug":slug});
        // console.log("Scrittura: deleteEvent("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }



  getMemberships(q = null, page = null){
    let composedQuery = query(collection(this.db, "memberships"));
    if(q && q.year){
      composedQuery = query(composedQuery, where("year", "==", q.year));
    }
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getMemberships("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getMembership(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "memberships", slug)).then((response) => {
        // console.log("Lettura: getMembership("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addMembership(item){
    return new Promise((resolve, reject) => {
      addDoc(collection(this.db, "memberships"), item).then((response) => {
        // console.log("Scrittura: addMembership()");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateMembership(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "memberships", slug), item).then((response) => {
        this.addLog("updateMembership", {"slug":slug, "item":item});
        // console.log("Scrittura: updateMembership("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteMembership(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "memberships", slug)).then((response) => {
        this.addLog("deleteMembership", {"slug":slug});
        // console.log("Scrittura: deleteMembership("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  
}

let instance = new DatabaseService();
export default instance;