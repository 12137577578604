import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';


import AnalyticsService from './../../services/AnalyticsService';



import Section from '../layout/Section';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '../../common/components/Card'
import Typo from '../../common/components/Typo'
import Meta from './../layout/Meta';

import Layout from '../layout/Layout';


const ScrollTrigger = styled.div`
  cursor: pointer;
  transition: transform 50ms ease-in-out;

&:hover {
  transform: rotate(2deg);
}
`;
const ScrollRef = styled.div`
  scroll-margin: 70px;
`;

const Disciplines = ({layout}) => {
  let { lang } = useParams();
  
  const scrollTo1 = useRef();
  const scrollTo2 = useRef();
  const scrollTo3 = useRef();
  const scrollTo4 = useRef();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Discipline - Attività" : "xxx"}
        languages={{"it": "activities/disciplines"}}
      />
      <Section variant="light">
        <Row>
          <Col md={12}>  
            <Typo type="h0" align="center">Attività</Typo>
            <Typo type="h1" align="center">{lang === "it" ? "Discipline" : "xxx"}</Typo>
            <Typo type="p" align="center">Proponiamo molte delle discipline che siamo abituati a vedere nell’equitazione tradizionale, che con qualche accorgimento, possono essere trasformate nella loro versione etica, mantenendo un dialogo piuttosto che un monologo.</Typo>
          </Col>
        </Row>
        <br/><br/>
        <Row>
          <Col md={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollTo1.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card>
                <img src="https://placehold.co/2000x1600" alt="Salto Ostacoli" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Salto Ostacoli" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col md={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollTo2.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card>
                <img src="https://placehold.co/2000x1600" alt="Dressage" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Dressage" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col md={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollTo3.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card>
                <img src="https://placehold.co/2000x1600" alt="Horse Trail" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Horse Trail" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col md={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollTo4.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card>
                <img src="https://placehold.co/2000x1600" alt="Volteggio" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Volteggio" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
        </Row>
        <br/><br/>
      </Section>
      <Section variant="white">
        <Row>
          <Col md={6}>  
            <ScrollRef ref={scrollTo1} /> 
            <Typo type="h2" align="center">{lang === "it" ? "Salto Ostacoli" : "xxx"}</Typo>
            <Typo type="p" align="center">Il salto ostacoli è un percorso di salti, diverso per altezza e difficoltà a seconda della categoria.</Typo>
            <Typo type="p" align="center">Restando nel range di possibilità fisiche e mentali del cavallo può essere un ottimo esercizio di ginnastica e di consapevolezza del proprio corpo, sia per il cavallo che per il cavaliere. Allenamento non solo del fisico, a cui serve una corretta ginnastica, ma anche della comunicazione, per renderla chiara e precisa.</Typo>
          </Col>
          <Col md={6}>  
            <img src="https://placehold.co/2000x1600" alt="Volteggio" width="100%" />
          </Col>
        </Row>
      </Section>



      
    </Layout>
  )
}

export default Disciplines;
