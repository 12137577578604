import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import AnalyticsService from '../../services/AnalyticsService';

import Section from '../layout/Section';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';

import {LAYOUT_SM, LAYOUT_XS } from '../../config';

const Intro = styled.div`
  background-color: #fbf2c0;
  margin-bottom: 100px;
`;
const Image = styled.img`
  margin-top: -60px;
  margin-bottom: -80px;
  width: 100%;
`;
const ImageMobi = styled.img`
  width: 100%;
`;
const ImageFull = styled.img`
  width: 100%;
`;

const Lessons = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Chi siamo" : "About us"}
        languages={{"it": "chi-siamo", "en":"about-us"}}
      />
      <Section>
        <Typo type="h1" align="center">{lang === "it" ? "Chi siamo" : "About us"}</Typo>
      </Section>
      <Intro>
        <Section>
          <Row>
            {layout !== LAYOUT_XS && layout !== LAYOUT_SM ? 
              <Col md={6}>
                <Image src="/photos/about.jpg" alt={lang === "it" ? "Carezze a Equinozio OdV" : "Cuddles at Equinozio OdV"} />
              </Col>
            : null}
            <Col md={6}>
              {lang === "it" ? 
                <>
                  <Typo type="p" align="left">
                    L'Associazione Equinozio OdV nasce dal desiderio di assicurare un futuro a tutti quei cavalli o equidi in generale che, per i più svariati motivi, si trovano in pericolo o senza una famiglia.<br/>Uno dei nostri obiettivi principali è quello di diffondere il concetto di cavallo come AMICO e compagno di vita e non come strumento utile a raggiungere i nostri scopi personali.  
                  </Typo>
                  <Typo type="p" align="left">
                    Molto spesso il cavallo viene considerato come oggetto e quando "si deteriora" viene letteralmente scartato e la sua vita cambia radicalmente passando dalle lezioni o dalle passeggiate in compagnia ad una deprimente solitudine... Così i cavalli si lasciano andare, diventano apatici e piano piano si spengono.  
                  </Typo>
                  <Typo type="p" align="left">
                    L'associazione quindi si propone di aiutare cavalli maltrattati o che il proprietario non può più mantenere, cavalli destinati al macello, o all'eutanasia in quanto ormai anziani, o seppur giovani con problematiche fisiche e psicologiche.  
                  </Typo>                
                </>
              : 
                <>
                  <Typo type="p" align="center">
                    The Equinozio OdV association was founded out of a desire to ensure a future for all horses or equids in general that, for a variety of reasons, find themselves in danger or without a family.<br/>One of our main objectives is to spread the concept of the horse as a FRIEND and life companion and not as a tool to achieve our personal goals.
                  </Typo>
                  <Typo type="p" align="left">
                    Very often the horse is considered as an object and when it "deteriorates" it is literally discarded and its life changes radically from lessons or walks in company to a depressing solitude... So the horses let themselves go, they become apathetic and slowly switch off.
                  </Typo>
                  <Typo type="p" align="center">
                    The association aims to help horses that have been mistreated or that the owner can no longer maintain, horses that are destined for slaughter, or for euthanasia because they are old, or even young horses with physical and psychological problems.
                  </Typo>             
                </>
              }
            </Col>
            {layout === LAYOUT_XS || layout === LAYOUT_SM ? 
              <Col md={6}>
                <ImageMobi src="/photos/about.jpg" alt={lang === "it" ? "Cavallo affettuoso a Equinozio OdV" : "Affectionate horse at Equinozio OdV"} />
              </Col>
            : null}
          </Row>
        </Section>
      </Intro>
      <Section>
        <Typo type="h2" align="center">{lang === "it" ? "Che cosa facciamo?" : "What are we going to do?"}</Typo>
        <Typo type="p">
          {lang === "it" ? 
            <ul>
              <li>Salviamo equidi e li aiutiamo  sia dal punto di vista fisico che da quello psicologico.</li>
              <li>Troviamo famiglie nuove per i cavalli salvati.</li>
              <li>Siamo di supporto a chi si impegna ad aiutare cavalli bisognosi.</li>
              <li>Organizziamo raccolte fondi, banchetti e cene per sostenere le spese del mantenimento, dei veterinari, dei medicinali e integratori dei nostri e altri cavalli.</li>
              <li>Organizziamo corsi e giornate a tema per far conoscere il mondo del cavallo.</li>
            </ul>
          :
            <ul>
              <li>We rescue equines, help them both physically and psychologically.</li>
              <li>We find new families for the rescued ones.</li>
              <li>Whoever is committed to helping horses in need has our support.</li>
              <li>We organise fundraisers, banquets and charity dinners to contribute to the ordinary expenses of maintaining the horses, but also for veterinarians, medicines and supplements for our animals and others.</li>
              <li>We organize courses and themed days to introduce the world of horses.</li>
            </ul>
          }
        </Typo>
      </Section>
      {/*
      <Section>
        <Typo type="h3" align="center">I nostri punti saldi</Typo>
        <Row>
          <Col md={6}>
            <Infobox
              icon="foraging"
              title="Spazio e foraging"
              text="Arrediamo le spaziose gabbie con rami naturali e elementi di foraging, in modo che siano il più sicure ed accoglienti possibile."
            />
          </Col>
          <Col md={6}>
            <Infobox
              icon="flying"
              title="Volo e attività"
              text="I nostri pappagalli escono quotidianamento e sfruttano la grande stanza per acrobazie aeree e attività in nostra compagnia."
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Infobox
              icon="reinforcement"
              title="Rinforzo positivo"
              text="Vogliamo che i nostri pappagalli siano pronti ad affrontare la vita domestica e che imparino a sfruttare tutte le loro capacità mentali, quindi li introduciamo al clicker training e al target, per metterli in condizione di imparare con entusiasmo e senza stress."
            />
          </Col>
          <Col md={6}>
            <Infobox
              icon="trust"
              title="Conquista della fiducia"
              text="Ci poniamo sempre in modo da costruire un rapporto, non pretenderlo. Ad esempio, non afferriamo i pappagalli contro la loro volontà: se dobbiamo spostarli e non si fidano delle nostre mani, accostiamo le gabbie e creiamo un percorso obbligato invogliandoli con delle ricompense."
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Infobox
              icon="feeding"
              title="Alimentazione"
              text="Forniamo un'alimentazione sana e varia: frutta e verdura fresche, cereali, estrusi di prima qualità, semi, ecc..."
            />
          </Col>
          <Col md={6}>
            <Infobox
              icon="selection"
              title="Selezione"
              text="Seguiamo gli standard della specie e selezioniamo i piccoli per formare nuove coppie che ci daranno esemplari sempre più conformi."
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Infobox
              icon="couples"
              title="Rispetto delle coppie"
              text="Non separiamo MAI coppie di pappagalli affiatate. Se la coppia invece non va più d'accordo, diamo la possibilità alle coppie di riformarsi secondo le loro preferenze."
            />
          </Col>
          <Col md={6}>
            <Infobox
              icon="broods"
              title="Covate limitate"
              text="Non facciamo mai più di due covate l'anno, anche se non vanno a buon fine. A volte ne facciamo anche di meno, se la covata precedente è stata molto impegnativa o se qualcosa non va."
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Infobox
              icon="shared"
              title="Allevamento condiviso"
              text="Alleviamo i nostri pulli in condivisa con i genitori; con la loro collaborazione i piccoli crescono più sani ed equilibrati e la coppia non vive il trauma di vedersi togliere i figli prima del tempo."
            />
          </Col>
          <Col md={6}>
            <Infobox
              icon="weaning"
              title="Tempi di svezzamento"
              text="Non anticipiamo mai lo svezzamento: non abbiamo fretta di cedere i piccoli, e ci prendiamo tutto il tempo necessario perché lo svezzamento avvenga con i naturali ritmi del nucleo familiare."
            />
          </Col>
        </Row>
      </Section>
    */}
      <ImageFull src="/photos/about2.jpg" alt={lang === "it" ? "Cavallo curioso a Equinozio OdV" : "Curious horse at Equinozio OdV"} />
    </Layout>
  )
}

export default Lessons;
