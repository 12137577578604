import React from 'react';
import styled from 'styled-components';

const Wrapper  = styled.div`
`;
const Item  = styled.a`
  display: inline-block;
  width: 42px;
  height: 42px;
  background-color: #ffffff26;
  margin-right: 12px;
  border-radius: 42px;
  &:hover{
    background-color: #64B6AC;
  }
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
`;
const Icon  = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;

  background-color: #ffffff;
  mask: url("${props => props.src}") no-repeat 100% 100%;
  mask-size: 100% 100%;
`;

const Socials = ({ inverse }) => {
  return (
    <Wrapper>
      <Item href="https://www.instagram.com/martinapassanante" target="_blank" social="instagram" inverse={inverse}>
        <Icon src={process.env.PUBLIC_URL + "/icons/social-instagram.png"} />
      </Item>
    </Wrapper>
  )
}



export default Socials
