import React from 'react';
import styled from 'styled-components';
// import OzCheck from "./../OzCheck/OzCheck.js";


const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 0px;
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }


  @media screen and (max-width: 600px) {
    & thead {
      display: none;
    }  
    & td {
      display: flex;
    }
    
    & td::before {
      content: attr(label);
      font-weight: bold;
      width: 120px;
      min-width: 120px;
    }
  }
`;
const THead = styled.thead`
  & tr{
    border-bottom: 1px solid #707070;
    /*
    box-shadow: 0px 2px 10px #C1CCE838;
    z-index: 2;
    position: relative; */
  }
  & tr th{
    font-family: "Young Serif", sans-serif;
    padding: 15px 10px 15px 10px;
    font-size: 16px;
    font-weight: 400;
    color: #5E0B15;
  }
`;
const TBody = styled.tbody`
  & tr:nth-child(odd){
    background-color: #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    /*z-index: 1;
    position: relative;*/
  }
  @media screen and (min-width: 601px) {
    & tr td{
      border-bottom: 1px dashed #70707029;
    }
    & tr:nth-child(odd) td{
      border-bottom: 1px dashed #50505029;
    }
  }
  @media screen and (max-width: 600px) {
    & tr td:last-child{
      border-bottom: 1px dashed #707070;
      padding-bottom: 30px;
      // margin-bottom: 30px;
    }
    & tr td:first-child{
      padding-top: 30px;
    }
  }
  & tr td a{
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
`;
const Row = styled.tr`
  ${props => props.background ? "background-color: " + props.background + " !important;" : null}

  & td{
    ${props => props.color ? "color: " + props.color + ";" : null}
  }

  &:hover td{
    background-color: ${props => props.backgroundHover ? props.backgroundHover : "lilac"};
  }
`;
const Cell = styled.div`

@media screen and (min-width: 601px) {
  font-size: 14px;
  padding: 15px 10px 15px 10px;
}
`;

const Table = ({ columns, values, selectable = false, selected = [], onSelect = () => {} }) => {
  return (
    <TableWrapper>
      <THead>
        <tr>
          {selectable ?
            <th width="20px"></th>
          : null }
          {columns.map((item, i) =>
            <th key={i}>
              {item}
            </th>
          )}
        </tr>
      </THead>
      <TBody>
        {values.map((row, i) => {
            const Link = row.link;
            return (<Row
              key={i}
              background={row.style && row.style.background ? row.style.background : null}
              backgroundHover={row.style && row.style.backgroundHover ? row.style.backgroundHover : null}
              color={row.style && row.style.color ? row.style.color : null}
            >
              {selectable ?
                <td width="20px">
                    {/*<Link><Cell>
                      <div onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                      <OzCheck
                        selected={selected.includes(row.id)}
                        onChange={() => onSelect(row.id)}
                      />
                      </div>
                    </Cell></Link>*/}
                  <Cell>
                    {/* <OzCheck
                      selected={selected.includes(row.id)}
                      onChange={() => onSelect(row.id)}
                    /> */}
                  </Cell>
                </td>
              : null }
              {row.values.map((item, j) =>
                <td key={j} label={columns[j]}>
                  {Link ?
                    <Link><Cell>{item}</Cell></Link>
                  :
                    <Cell>{item}</Cell>
                  }
                </td>
              )}
            </Row>);
          }
        )}
      </TBody>
    </TableWrapper>
  )
}

export default Table
