import React, {useState, useEffect, useCallback} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from '../layout/Layout';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card';
import Loading from '../../common/components/Loading';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Success from '../../common/components/Success';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-family: "Young Serif", sans-serif;
  font-size: 30px;
  color: #5E0B15;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-bottom: 5px;
`;
const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;

const MembershipsSingle = ({layout}) => {
  const {slug} = useParams();
  const [item, setItem] = useState();

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    // if(!item.name || item.item.length === 0){
    //   valid = false;
    // }
    setDisabled(!valid);
  }, [item]);


  const getItem = useCallback(() => {
    DatabaseService.getMembership(slug).then((response) => {
      if(response.success){
        setItem(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);



  const updateItem = useCallback(() => {
    setErrorMessage("");
    DatabaseService.updateMembership(slug, item).then((response) => {
      if(response.success){
        setSuccessMessage("Dati salvati correttamente!");
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, item]);


  useEffect(() => {
    getItem();
  }, [slug, getItem]);

  if(item){
    return (
      <Layout layout={layout}>
        <Container>
          
          <Header>
            <Title>Tesseramenti: {item.givenName} {item.familyName} ({item.year})</Title>    
          </Header>

          <Row>
            
          <Col>
              <Card
                collapsible={true}
                collapsed={false}
                headerLeft={
                  <>
                    <CardHeaderTitle>Stato burocratico</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  
                  <Col widthmd="3">
                    <Select
                      label="Pagamento verificato"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.hasPaid}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.hasPaid = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Registrazione inviata"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isSubmitted}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isSubmitted = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Tessera consegnata"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.hasCard}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.hasCard = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={3}>
                    <Input
                      label="N° tessera"
                      width="100%"
                      value={item.number}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.number = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                collapsible={true}
                collapsed={true}
                headerLeft={
                  <>
                    <CardHeaderTitle>Dati di registrazione</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd={6}>
                    <Input
                      label="Nome"
                      width="100%"
                      value={item.givenName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.givenName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Cognome"
                      width="100%"
                      value={item.familyName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.familyName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Email"
                      width="100%"
                      value={item.email}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.email = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Codice fiscale"
                      width="100%"
                      value={item.taxCode}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.taxCode = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Data di nascita"
                      width="100%"
                      value={item.birthDate}
                      type="date"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.birthDate = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Luogo di nascita"
                      width="100%"
                      value={item.birthPlace}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.birthPlace = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Comune di residenza"
                      width="100%"
                      value={item.city}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.city = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="CAP di residenza"
                      width="100%"
                      value={item.zip}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.zip = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Indirizzo di residenza"
                      width="100%"
                      value={item.address}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.address = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>  
                </Row>
              </Card>
            </Col>
            
          </Row>
          
          <ErrorMessage>
            <br/>{errorMessage}<br/>
          </ErrorMessage>

          <div style={{"float": "right"}}><Button onClick={() => updateItem()} disabled={disabled}>Salva</Button></div>
          <Link to={"/admin/memberships"}><Button variant={"default-o"}>Indietro</Button></Link>
          

        </Container>

        {successMessage ?
          <Success fnClose={() =>setSuccessMessage(false)} message={successMessage} />
        : null}

      </Layout>
    )
  }else{
    return <Loading />;
  }
}

export default MembershipsSingle;
