import React, {useRef, useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from './../../services/DatabaseService';
import HorsesModalNew from './HorsesModalNew';

import Layout from './../layout/Layout';
import Filters from './../layout/Filters';

import Container from './../../common/components/Container';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Table from './../../common/components/Table';
import Loading from './../../common/components/Loading';
import Card from './../../common/components/Card';
import Button from './../../common/components/Button';

import {addMonths} from '../../helpers';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
  overflow-x: auto; 

  & a > div {
    color: #000000;
  }
`;
// const TableActions = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;

//   & > *{
//     margin-left: 5px;
//   }
// `;
const NoResult = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-family: "Young Serif", sans-serif;
  font-size: 30px;
  color: #5E0B15;
`;
const Tag = styled.div`
  display: inline-block;
  font-weight: 600;
  border-radius: 20px;
  padding: 0px 5px 1px;
  margin: 2px;
  ${props => props.status === 1 ? `
    color: white;
    background: green;
  ` : props => props.status === -1 ? `
    color: white;
    background: red;
  ` : `
    color: white;
    background: grey;
  `}
  text-transform: uppercase;
`;
const MiniTag = styled.div`
  display: inline-block;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
`;


const HorsesList = ({layout}) => {
  let scrollRef = useRef(null);
  const {setOverlay} = useContext(OverlayContext);
  // let navigate = useNavigate();
  const [list, setList] = useState(null);
  const [page, setPage] = useState(0);

  // const [mapSettings, setMapSettings] = useState({
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627
  //   },
  //   zoom: 11
  // });

  // const [showModalNew, toggleModalNew] = useState(false);

  const [filters, setFilters] = useState({
    "isHere": 1,
    "isDeceased": -1
  });

  const changeFilters = useCallback((name, value) => {
    setList(null);
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    if(scrollRef && scrollRef.current){
      scrollRef.current.scrollTo(0,0);
    }
    DatabaseService.getHorses({
      page: page,
      isHere: filters.isHere,
      isInBDN: filters.isInBDN,
      isCorrectOwner: filters.isCorrectOwner,
      isCorrectBarncodeInBDN: filters.isCorrectBarncodeInBDN,
      isDeceased: filters.isDeceased,
      // start: filters.rangeDate.split('|')[0],
      // end: filters.rangeDate.split('|')[1],
      // level: filters.level
    }).then((response) => {
      if(response.success){
        setList(response.data);
      }else{
        console.log(response.error);
      }
    });

  }, [page, filters]);

  useEffect(() => {
    getList();
  }, [getList, page, filters]);

  // const deleteItem = useCallback((item) => {
  //   setList(null);
  //   DatabaseService.deleteHorse(item.slug).then((response) => {
  //     if(response.success){
  //       getList();
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, [getList]);


  return (
    <Layout layout={layout}>
      <div ref={scrollRef}>
        <Container>
          <Header>
            <Title>Cavalli {list ? <small>({list.length})</small> : null}</Title>            
            <Button onClick={() => setOverlay(<HorsesModalNew />)}>Nuovo</Button>
          </Header>

          <Row>
            <Col>
              <Card>
                <Row>
                  <Col widthmd="12">
                    <Filters
                      onChange={(...args) => changeFilters(...args)}
                      fields={
                        [
                          {
                            "label": "In struttura",
                            "name": "isHere",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.isHere,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },{
                                "name": "Sì",
                                "value": 1
                              },{
                                "name": "No",
                                "value": -1
                              },{
                                "name": "-",
                                "value": 0
                              }
                            ]
                          },{
                            "label": "BDN",
                            "name": "isInBDN",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.isInBDN,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },{
                                "name": "Sì",
                                "value": 1
                              },{
                                "name": "No",
                                "value": -1
                              },{
                                "name": "-",
                                "value": 0
                              }
                            ]
                          },{
                            "label": "Intestato corr.",
                            "name": "isCorrectOwner",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.isCorrectOwner,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },{
                                "name": "Sì",
                                "value": 1
                              },{
                                "name": "No",
                                "value": -1
                              },{
                                "name": "-",
                                "value": 0
                              }
                            ]
                          },{
                            "label": "Stallato corr.",
                            "name": "isCorrectBarncodeInBDN",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.isCorrectBarncodeInBDN,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },{
                                "name": "Sì",
                                "value": 1
                              },{
                                "name": "No",
                                "value": -1
                              },{
                                "name": "-",
                                "value": 0
                              }
                            ]
                          },{
                            "label": "Deceduto",
                            "name": "isDeceased",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.isDeceased,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },{
                                "name": "Sì",
                                "value": 1
                              },{
                                "name": "No",
                                "value": -1
                              },{
                                "name": "-",
                                "value": 0
                              }
                            ]
                          }
                        ]
                      }
                    />
                    <hr/>
                    {list ?
                      list.length > 0 ?
                        <>
                          <TableWrapper>
                            <Table
                              columns={[
                                "",
                                "Nome",
                                "UELN/CHIP/DOC",
                                "Proprietario",
                                // "BDN",
                                // "Intestato",
                                // "Cod. stalla",
                                "Note",
                                "",
                                "",
                                // ""
                              ]}
                              values={
                                list.map(item => ({
                                  style:{
                                    background: ((item.isInBDN !== 1 || item.isCorrectOwner !== 1 || item.isCorrectBarncodeInBDN !== 1) && item.isDeceased !== 1) || (item.isDeceasedReported !== 1 && item.isDeceased === 1) ? "#f1f1f199" : "transparent",
                                    color: item.isDeceased === 1 ? "#00000033" : "#000000"
                                  },
                                  values: [
                                    <>
                                      <MiniTag>{item.species ? item.species.slice(0,2) : "??"}</MiniTag><br/>
                                      <MiniTag>{item.sex ? item.sex.slice(0,1) : "?"}</MiniTag>
                                    </>,
                                    <>
                                      {item.name}<br/>
                                      {item.docname && item.name !== item.docname ? "("+item.docname+")" : null}                                      
                                    </>,            
                                    <>
                                      {item.uelnnumber ? item.uelnnumber : "N/A"}<br/>
                                      {item.chipnumber ? item.chipnumber : "N/A"}<br/>
                                      {item.doctype}<br/>
                                      {item.docnumber ? item.docnumber : "N/A"}
                                    </>,            
                                    <>
                                      {item.ownerName ? item.ownerName : "N/A"}<br/>
                                      {item.ownerPhone}<br/>
                                    </>,                    
                                    item.notes && item.notes.length > 0 ? item.notes.slice(0, 15)+"..." : "",
                                    <>
                                      <Tag status={
                                        item.lastHoovesTrim && item.hoovesTrimInterval ? 
                                          addMonths(new Date(item.lastHoovesTrim.seconds*1000), item.hoovesTrimInterval) > (new Date()) ? 1 : -1
                                        : 
                                          0
                                      }>P:{item.hoovesTrimInterval ? item.hoovesTrimInterval : "?"}</Tag><br/>
                                      <Tag status={
                                        item.lastDentist && item.dentistInterval ? 
                                          addMonths(new Date(item.lastDentist.seconds*1000), item.dentistInterval) > (new Date()) ? 1 : -1
                                        : 
                                          0
                                      }>D:{item.dentistInterval ? item.dentistInterval : "?"}</Tag>
                                    </>,
                                    <>
                                    <Tag status={item.isHere}>QUI</Tag><br/>
                                    {item.isDeceased === 1? 
                                      <>
                                        <Tag status={item.isDeceasedReported}>REG</Tag>
                                      </>
                                    : 
                                      <>
                                        {item.isDeceased !== -1? 
                                          <>
                                            <Tag status={item.isDeceased}>DEC?</Tag><br/>
                                          </>
                                        : null }
                                        <Tag status={item.isInBDN}>BDN</Tag>
                                        <Tag status={item.isCorrectOwner}>PROP</Tag>
                                        <Tag status={item.hasOriginalDocs}>DOC</Tag>
                                        <Tag status={item.isCorrectBarncodeInBDN}>CS</Tag>
                                      </>
                                    }
                                  </>,
                                    // <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                    //   <Button size={"small"} onClick={() => deleteItem(item)}>Elimina</Button>
                                    // </TableActions>
                                  ],
                                  link: ({children}) => (<Link to={"/admin/horses/"+item.slug}>{children}</Link>)
                                }))
                              }
                            />
                          </TableWrapper>
                        </>
                      : <NoResult>There are no results.</NoResult>
                    : <Loading />}
                  </Col>
                </Row>
                {/* {totPages > 1 ?
                  <OzPagination totPages={totPages} currentPage={page} onChange={((val) => setPage(val))} />
                : null } */}
              </Card>
            </Col>
          </Row>

          
        </Container>
      </div>
    </Layout>
  )
}

export default HorsesList;
