import React from 'react'
import styled from 'styled-components'

import Container from 'react-bootstrap/Container';


const Wrapper = styled.section`
  padding: 50px 0px 50px;


  ${props => props.variant === "white" ? `
    background-color: #ffffff;
    color: #000000;
  ` : ""}

  ${props => props.variant === "light" ? `
    background-color: #EDDDD4;
    color: #000000;
  ` : ""}

  ${props => props.variant === "primary" ? `
    background-color: #5E0B15;
    ` + (props.image ?  
      `background-image: linear-gradient(259deg, #0000002b 18%, #5E0B15 80%), url('`+props.image+`');`
    : null ) + `
    color: #000000;
    background-size: cover, cover;
    background-position: center center;
  ` : ""}

  
`;


const Section = ({ fluid, variant="default", image=null, children }) => {
  return (
    <Wrapper variant={variant} image={image}>
      <Container fluid={fluid}>
        {children}
      </Container>
    </Wrapper>
  )
}


export default Section
