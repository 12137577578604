import React from 'react';
import { useLocation } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';

const Meta = ({title = null, description = null, image = null, languages = {}}) => {
  const location = useLocation();
  let { lang } = useParams();
  return (
    <Helmet>
      <title>{title ? title + " | Martina Passanante" : "Martina Passanante"}</title>
      <meta property="og:title" content={title ? title + " | Martina Passanante" : "Martina Passanante"} />
      {description ? 
        <meta name="description" content={description} />
      : 
        <meta name="description" content={lang === "it" ? "TODO Claim IT" : "TODO Claim EN"} />
      }
      {description ? 
        <meta property="og:description" content={description} />
      : 
        <meta property="og:description" content={lang === "it" ? "Aiutaci a salvare chi non può salvarsi da solo!" : "Aiutaci a salvare chi non può salvarsi da solo!"} />
      }
      {image ? 
        <meta property="og:image" content={image} />
      : 
        <meta property="og:image" content="https://www.martinapassanante.it/photos/header.jpg" />
      }
      <meta property="og:type" content="website" />
      <meta property="og:url" content={"https://www.martinapassanante.it"+location.pathname} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />

      {Object.keys(languages).map((key) => 
        <link key={key} rel="alternate" hreflang={key} href={"https://www.martinapassanante.it/"+key+"/"+languages[key]} />
      )}
    </Helmet>
  )
}

export default Meta;