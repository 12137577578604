import React from "react";
import styled from 'styled-components';


const Wrapper  = styled.div`
  font-family: 'Raleway', sans-serif;
  display: ${props => props.display};
  width: ${props => props.width};
  ${props => props.display === "inline" ? "z-index: 3;" : null}
  vertical-align: middle;
  border-radius: 42px;
  border: 2px solid black;
  color: #000;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  text-align: center;
  background-clip: border-box;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  box-sizing: border-box;
  letter-spacing: 1px;
  font-size: inherit;
  cursor: ${props => props.disabled ? "default" : "pointer"};


  pointer-events: ${props => props.noHover ? "none" : "auto"};box-shadow: 0px 1px 2px #687FE262;

  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;

  &, & * {
    box-sizing: border-box;
  }

  &:active, &:focus{
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }

  &:hover,
  &:hover *{
    text-decoration: none;
  }

  ${props => props.disabled ? `
    pointer-events: none;
    opacity: 0.5;
  ` : `
    opacity: 1;
  `};

  ${props => props.size === "medium" ? `
    height: 42px;
    padding: 0px 24px 0px;
    min-width: 120px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  ` : props => props.size === "small" ? `
    height: 26px;
    padding: 0px 12px 0px;
    min-width: 80px;
    font-size: 10px;
    font-weight: 800;
    line-height: 10px;
  ` : null };

  ${props => props.variant === "default" ? `
    color: #ffffff !important;
    background-color: #5E0B15;
    border-color: #5E0B15;

    &:hover{
      color: #64B6AC !important;
      background-color: #254441;
      border-color: #254441;
    }
  ` : props.variant === "default-o" ? `
    color: #5E0B15 !important;
    background-color: #ffff;
    border-color: #5E0B15;

    &:hover{
      color: #254441 !important;
      background-color: #ffffff;
      border-color: #254441;
    }
  ` : props.variant === "white" ? `
    color: #5E0B15 !important;
    background-color: #ffffff;
    border-color: #ffffff;

    &:hover{
      color: #254441 !important;
      background-color: #64B6AC;
      border-color: #64B6AC;
    }
  ` : `

  `};
`;


const Content = styled.div`
  color: inherit !important;
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;


const Button = ({
    variant = "default",
    size = "medium",
    display = "inline-block",
    width = "auto",
    disabled = false,
    noHover = false,
    onClick,
    children
  }) => {
  return (
    <Wrapper
      variant={variant}
      size={size}
      display={display}
      width={width}
      onClick={onClick}
      disabled={disabled}
      noHover={noHover}
    >
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}

export default Button;
