import React from 'react';
import styled from 'styled-components';
import Icon from "./Icon.js";

const Wrapper = styled.div`
  &, & * {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${props => props.variant === "default" ? `
    border: 1px solid ` + (props.disabled ? "#CBCBCB21" : "#CBCBCB") + `;
    background: ` + (props.disabled ? "#EBEBEB" : props.selected ? "#ffffff" : "#ffffff") + `;
  ` : props.variant === "charcoal" ? `
    border: 1px solid ` + (props.disabled ? "#20283321" : "#202833") + `;
    background: ` + (props.disabled ? "#EBEBEB" : props.selected ? "#455262" : "#455262") + `;
  ` : null };
`;



const Check = ({
  variant = "default",
  label,
  description,
  selected = false,
  onChange,
  disabled = false,
  required = false
}) => {

  return (
    <Wrapper>
      <Container
        variant={variant}
        disabled={disabled}
        selected={selected}
        onClick={() => {
          if(!disabled){
            if(onChange){
              onChange(!selected);
            }
          }
        }}
      > 
        {selected ?
          <Icon name="tick" size={"20px"}/>
        : null }
      </Container>
    </Wrapper>
  )
}

export default Check;
