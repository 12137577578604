import React from "react";
import styled from 'styled-components';
import Slider from "react-slick";
import { Link } from "react-router-dom";

// const MONTHS = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
const MONTHS = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

const SCHEDULE = {
  3: {
    16: {
      "hour": "09:00-12:00",
      "type": "outdoor-excursion",
      "text": "Escursione Esterna [1°]",
      "link": "/it/paths/outdoor-excursion"
    },
    17: {
      "hour": "09:00-11:00",
      "type": "dressage-basic",
      "text": "Dressage (Base) [1°]",
      "link": "/it/paths/dressage-basic"
    },
    23: {
      "hour": "15:00-18:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [1° (A)]",
      "link": "/it/paths/mock-excursion"
    }
  },
  4: {
    13: {
      "hour": "15:00-17:00",
      "type": "dressage-basic",
      "text": "Dressage (Base) [2°]",
      "link": "/it/paths/dressage-basic"
    },
    20: {
      "hour": "15:00-18:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [1° (B)]",
      "link": "/it/paths/mock-excursion"
    },
    21: {
      "hour": "09:00-12:00",
      "type": "outdoor-excursion",
      "text": "Escursione Esterna [2°]",
      "link": "/it/paths/outdoor-excursion"
    },
    27: {
      "hour": "15:00-18:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [2° (A)]",
      "link": "/it/paths/mock-excursion"
    },
    28: {
      "hour": "09:00-11:00",
      "type": "dressage-basic",
      "text": "Dressage (Base) [3°]",
      "link": "/it/paths/dressage-basic"
    }
  },
  5: {
    4: {
      "hour": "15:00-18:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [2° (B)]",
      "link": "/it/paths/mock-excursion"
    },
    5: {
      "hour": "09:00-12:00",
      "type": "outdoor-excursion",
      "text": "Escursione Esterna [3°]",
      "link": "/it/paths/outdoor-excursion"
    },
    11: {
      "hour": "17:00-19:00",
      "type": "dressage-basic",
      "text": "Dressage (Base) [4°]",
      "link": "/it/paths/dressage-basic"
    },
    12: {
      "hour": "09:00-12:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [3° (A)]",
      "link": "/it/paths/mock-excursion"
    },
    25: {
      "hour": "15:00-18:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [3° (B)]",
      "link": "/it/paths/mock-excursion"
    },
    26: {
      "hour": "09:00-11:00",
      "type": "dressage-basic",
      "text": "Dressage (Base) [5°]",
      "link": "/it/paths/dressage-basic"
    },
  },
  6:{
    1: {
      "hour": "16:00-19:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [4° (A)]",
      "link": "/it/paths/mock-excursion"
    },
    15: {
      "hour": "16:00-19:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [4° (B)]",
      "link": "/it/paths/mock-excursion"
    },
    16: {
      "hour": "08:00-11:00",
      "type": "mock-excursion",
      "text": "Escursione Simulata [Rec (A/B)]",
      "link": "/it/paths/mock-excursion"
    },
    17: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [1° sett.]",
      "link": "/it/paths/summer-camp"
    },
    18: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [1° sett.]",
      "link": "/it/paths/summer-camp"
    },
    19: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [1° sett.]",
      "link": "/it/paths/summer-camp"
    },
    20: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [1° sett.]",
      "link": "/it/paths/summer-camp"
    },
    21: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [1° sett.]",
      "link": "/it/paths/summer-camp"
    },
    
    23: {
      "hour": "09:00-12:00",
      "type": "outdoor-excursion",
      "text": "Escursione Esterna [4°]",
      "link": "/it/paths/outdoor-excursion"
    },

    24: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [2° sett.]",
      "link": "/it/paths/summer-camp"
    },
    25: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [2° sett.]",
      "link": "/it/paths/summer-camp"
    },
    26: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [2° sett.]",
      "link": "/it/paths/summer-camp"
    },
    27: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [2° sett.]",
      "link": "/it/paths/summer-camp"
    },
    28: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [2° sett.]",
      "link": "/it/paths/summer-camp"
    },
  },
  7:{
    1: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [3° sett.]",
      "link": "/it/paths/summer-camp"
    },
    2: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [3° sett.]",
      "link": "/it/paths/summer-camp"
    },
    3: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [3° sett.]",
      "link": "/it/paths/summer-camp"
    },
    4: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [3° sett.]",
      "link": "/it/paths/summer-camp"
    },
    5: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [3° sett.]",
      "link": "/it/paths/summer-camp"
    },

    8: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [4° sett.]",
      "link": "/it/paths/summer-camp"
    },
    9: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [4° sett.]",
      "link": "/it/paths/summer-camp"
    },
    10: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [4° sett.]",
      "link": "/it/paths/summer-camp"
    },
    11: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [4° sett.]",
      "link": "/it/paths/summer-camp"
    },
    12: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [4° sett.]",
      "link": "/it/paths/summer-camp"
    },

    15: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [5° sett.]",
      "link": "/it/paths/summer-camp"
    },
    16: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [5° sett.]",
      "link": "/it/paths/summer-camp"
    },
    17: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [5° sett.]",
      "link": "/it/paths/summer-camp"
    },
    18: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [5° sett.]",
      "link": "/it/paths/summer-camp"
    },
    19: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [5° sett.]",
      "link": "/it/paths/summer-camp"
    },

    22: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [6° sett.]",
      "link": "/it/paths/summer-camp"
    },
    23: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [6° sett.]",
      "link": "/it/paths/summer-camp"
    },
    24: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [6° sett.]",
      "link": "/it/paths/summer-camp"
    },
    25: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [6° sett.]",
      "link": "/it/paths/summer-camp"
    },
    26: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [6° sett.]",
      "link": "/it/paths/summer-camp"
    },

    29: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [7° sett.]",
      "link": "/it/paths/summer-camp"
    },
    30: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [7° sett.]",
      "link": "/it/paths/summer-camp"
    },
    31: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [7° sett.]",
      "link": "/it/paths/summer-camp"
    }
  },
  8:{
    1: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [7° sett.]",
      "link": "/it/paths/summer-camp"
    },
    2: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [7° sett.]",
      "link": "/it/paths/summer-camp"
    },
    

    5: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [8° sett.]",
      "link": "/it/paths/summer-camp"
    },
    6: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [8° sett.]",
      "link": "/it/paths/summer-camp"
    },
    7: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [8° sett.]",
      "link": "/it/paths/summer-camp"
    },
    8: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [8° sett.]",
      "link": "/it/paths/summer-camp"
    },
    9: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [8° sett.]",
      "link": "/it/paths/summer-camp"
    },

    

    26: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [9° sett.]",
      "link": "/it/paths/summer-camp"
    },
    27: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [9° sett.]",
      "link": "/it/paths/summer-camp"
    },
    28: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [9° sett.]",
      "link": "/it/paths/summer-camp"
    },
    29: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [9° sett.]",
      "link": "/it/paths/summer-camp"
    },
    30: {
      "hour": "08:00-16:00",
      "type": "summer-camp",
      "text": "Summer Camp [9° sett.]",
      "link": "/it/paths/summer-camp"
    },
  }
}

const Wrapper  = styled.div`
`;

const Year = styled.div`
  // margin: 96px auto;
  // width: 100%;
  // padding: 10px;
  // border: 1px solid #E0E0E0;
  // border-radius: 4px;
  // display: flex;
  // background: #FFF;
  // box-shadow: 0 10px 50px -15px rgba(0,0,0,0.25);

  // position: relative;
`;
const Month = styled.div`
  display: inline-block;
  padding: 0;
  margin: 0 2px 2px;
  list-style: none;
  flex: 1;

  position: relative;

  &:before{
    position: absolute;
    content: attr(data-month);
    color: #5E0B1588;
    font-family: "Young Serif", sans-serif;
    font-weight: 700;
    font-size: 24px;
    
    transform-origin: 0 100%;
    transform: rotate(90deg) translateX(-20px) translateY(0px);
    top: 0px;
    left: calc(100% - 40px);
    width: 300px;
  }

  &:first-child{
    margin-left: 0;
  }
  &:last-child{
    margin-right: 0;
  }
`;
const Day = styled.div`
  padding: 0;
  margin: 0 0 -1px 0;
  height: 26px;
  box-shadow: inset 0 0 0 1px #5E0B1522;
  background: #ffffff;
  white-space: nowrap;

  &:before{
    content: attr(data-date);
    color: transparent;
    text-shadow: 0 0 0 rgba(0,0,0,0.25);
    text-align: right;
    right: 2px;
    position: absolute;
    line-height: 28px;
    font-size: 10px;
  }

  &[data-day="0"], &[data-day="6"]{
    background: #EDDDD4;
    box-shadow: inset 0 0 0 1px #E0E0E0;
  }

  display: flex;
  align-items: center;
`;

const DayNumber = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding: 0px 5px;
  width: 30px;
  font-weight: 600;
  color: #5E0B15;
  font-family: "Young Serif", sans-serif;
`;

const DayContent= styled.div`
`;

const Activity= styled(Link)`
  display: flex;
  align-items: center;

  text-decoration: none !important;
`;
const ActivityHour= styled.span`
  font-weight: 600;
  margin-right: 5px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none !important;
  color: #000000;
`;
const ActivityText= styled.span`
  font-size: 16px;
  line-height: 16px;
  text-decoration: none !important;
  color: #000000;
`;


const Calendar = ({
    year = (new Date()).getFullYear(),
    filter = null,
    size = "normal"
  }) => {
  return (
    <Wrapper>
      <Year data-year={year}>
        <Slider 
          dots={false}
          infinite={false}
          slidesToShow={size === "normal" ? (window.screen.width < 768 ? 1 : 3) : 1}
          slidesToScroll={1}
          initialSlide={(new Date()).getMonth()}
        >
          {MONTHS.map((m, iM) => 
            <Month key={iM} data-month={m + " " + year}>
              {Array.from({length: new Date(year, iM+1, 0).getDate()}).map((d, iD) => 
                <Day key={iD} data-day={(new Date(year, iM, iD+1)).getDay()} data-date={d}>
                  <DayNumber>{iD+1}</DayNumber>
                  <DayContent>{
                    SCHEDULE[iM+1] ? 
                      SCHEDULE[iM+1][iD+1] ?
                        !filter || filter.includes(SCHEDULE[iM+1][iD+1].type) ? 
                          <Activity to={SCHEDULE[iM+1][iD+1].link}>
                            <ActivityHour>{SCHEDULE[iM+1][iD+1].hour}</ActivityHour>
                            <ActivityText>{SCHEDULE[iM+1][iD+1].text}</ActivityText>
                          </Activity>
                        : null
                      : null
                    : null
                  }</DayContent>
                </Day>
              )}
            </Month>
          )}
        </Slider>
      </Year>
    </Wrapper>
  )
}

export default Calendar;


