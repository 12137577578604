import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink, useParams, useLocation} from "react-router-dom";

import Container from './../../common/components/Container';

import Logo from './../../common/components/Logo';
// import Button from './../../common/components/Button';

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 80px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  background-color: #ffffff;
  z-index: 99;
`;

const Content = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
`;

const LogoCnt = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  z-index: 5;
  line-height: 0px;
`;

const MenuCnt = styled.div`
  @media (max-width: 991px){
    position: fixed;
    top: 0px;
    left: ${props => props.isOpen ? "0px" : "-100vw"};
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    padding-top: 80px;
    transition: all 0.25s ease-in-out;
  }
`;

const MobiIconsCnt = styled.div`
  z-index: 5;
  line-height: 0px;
  display: flex;
  @media (min-width: 992px){
    display: none;
  }
`;




const Nav = styled.div`
  overflow: hidden;

  white-space: nowrap;
  display: flex;
  flex-direction: row;
  list-style: none;

  @media (min-width: 992px){
    align-items: center;
  }

  @media (max-width: 991px){

    flex-direction: column;
    text-align: center;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.075) inset;
    max-height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
const Link = styled(NavLink)`
  font-size: 18px;
  float: left;
  display: block;
  margin: 17px 16px;
  text-decoration: none !important;
  
  &:hover, &:hover *{
    color: #64B6AC;
    text-decoration: none !important;
  }
`;
// const Dropdown = styled.div`
//   display: flex;
//   flex-direction: column;
//   float: left;
//   overflow: hidden;  
//   &:hover > *:last-child {
//     display: flex;
//   }
// `;
// const DropdownContent = styled.div`
//   display: none;
//   flex-direction: column;
//   @media (max-width: 991px){
//     display: flex;
//   }
//   @media (min-width: 992px){
//     position: absolute;
//     top: 59px;
//   }
//   flex-direction: column;
//   background-color: #ffffff;
//   box-shadow: 0 2px 8px rgba(0,0,0,0.08);
//   min-width: 160px;
//   z-index: 1;
// `;

const Item = styled.span`
  font-family: 'Young Serif', sans-serif;
  font-weight: 400;
  color: ${props => props.active ? "#64B6AC" : "#5e0b15"};
  text-decoration: none !important;
`;





const Brand = styled(NavLink)`
  font-family: 'Young Serif', sans-serif;
  font-size: 20px;
  @media (min-width: 576px){
    font-size: 23px;
  }
  @media (min-width: 1200px){
    font-size: 30px;
  }
  text-decoration: none !important;
  display: flex;
  align-items: center;
  gap: 10px;

  &, & *,
  &:hover,
  &:hover *{
    color: #5E0B15 !important;
    text-decoration: none !important;
  }
`;
const LogoWrapper = styled.div`
  width: 140px;
  height: 140px;
  position: relative;
  top: 20px;
  background: #fff;
  overflow: visible;
  border-radius: 100%;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);

  &::after{
    position: absolute;
    top: 0px;
    left: -10px;
    display: block;
    content: "";
    width: calc(100% + 20px);
    height: 90px;
    background: #ffffff;
    z-index: 2;
  }
  & img{
    position: absolute;
    top: 17px;
    left: 17px;
    z-index: 3;
  }
`;
const BrandName = styled.div`
  line-height: 30px;
`;
const MobiToggle = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const Topbar = () => {
  const {lang} = useParams();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapper>
      <Container>
        <Content>
          <LogoCnt>
            <Brand to={"/"+lang}><LogoWrapper><Logo width="106px" /></LogoWrapper> <BrandName>Martina<br/>&nbsp;Passanante</BrandName></Brand>
          </LogoCnt>
          <MenuCnt isOpen={isOpen}>
            <Nav className="me-auto">
              <Link exact="true" to={"/"+(lang ? lang : "it")+"/"}>
                {({ isActive }) => (<Item active={isActive}>Home</Item>)}
              </Link>
              <Link to={"/"+(lang ? lang : "it")+"/paths"}>
                <Item active={pathname.includes('/paths')}>
                  Percorsi
                </Item>
              </Link>
              <Link to={"/"+(lang ? lang : "it")+"/activities"}>
                <Item active={pathname.includes('/activities')}>
                  Attività
                </Item>
              </Link>
              <Link to={"/"+(lang ? lang : "it")+"/nuova-vita/membership"}>
                <Item active={pathname.includes('/membership')}>
                  Tesseramento
                </Item>
              </Link>
              {/* <Dropdown>
                <Link to={"/"+(lang ? lang : "it")+"/activities"}>
                  <Item active={pathname.includes('/activities')}>
                    Attività
                  </Item>
                </Link>
                <DropdownContent>
                  <Link to={"/"+(lang ? lang : "it")+"/activities/paths"}>
                    <Item active={pathname.includes('/activities/paths')}>
                      Percorsi
                    </Item>
                  </Link>
                  <Link to={"/"+(lang ? lang : "it")+"/activities/programs"}>
                    <Item active={pathname.includes('/activities/programs')}>
                      Programmi
                    </Item>
                  </Link>
                  <Link to={"/"+(lang ? lang : "it")+"/activities/disciplines"}>
                    <Item active={pathname.includes('/activities/disciplines')}>
                      Discipline
                    </Item>
                  </Link>
                </DropdownContent>
              </Dropdown> */}
              <Link exact="true" to={"/"+(lang ? lang : "it")+"/contacts"}>
                {({ isActive }) => (<Item active={isActive}>Contatti</Item>)}
              </Link>
            </Nav>
          </MenuCnt>
          <MobiIconsCnt>
            <MobiToggle onClick={() => setOpen(!isOpen)}>
              <span className="material-symbols-outlined">menu</span>
            </MobiToggle>
          </MobiIconsCnt>
        </Content>
      </Container>
    </Wrapper>
    
  )
}

export default Topbar

