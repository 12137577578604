import { initializeApp } from "@firebase/app";
import { getAuth, signInWithEmailAndPassword  } from "@firebase/auth";
// import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword  } from "@firebase/auth";

import {firebaseConfig} from './../config';

class AuthService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
  }

  // registration(email, password){
  //   return new Promise((resolve, reject) => {
  //     createUserWithEmailAndPassword(this.auth, email, password).then((response) => {
  //       localStorage.setItem("martinapassananteUser", JSON.stringify(response.user));
  //       let res = {};
  //       res.success = true;
  //       res.data = response.user;
  //       resolve(res);
  //     }).catch((error) => {
  //       reject(error);
  //     });
  //   });
  // }

  login(email, password){
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword (this.auth, email, password).then((response) => {
        localStorage.setItem("martinapassananteUser", JSON.stringify(response.user));
        let res = {};
        res.success = true;
        res.data = response.user;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  logout(){
    localStorage.setItem("martinapassananteUser", null);
  }


}

let instance = new AuthService();
export default instance;
