import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const PathsMedication = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Medicazioni - Percorsi" : "xxx"}
        languages={{"it": "paths/medication"}}
      />
      <Section variant="primary" image={"/photos/headers/percorso-medicazioni.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Percorso</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Medicazioni" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">Il percorso Medicazioni è pensato per trasferire tutte le conoscenze e le competenze che ho impiegato anni ad accumulare ma che idealmente bisognerebbe conoscere fin da subito.</Typo>
            <Typo type="p" color="#ffffff">Le informazioni di questo corso sono frutto:
              <ul>
                <li>della mia formazione da Tecnico della SEE</li>
                <li>della mia formazione da Tecnico di IFE</li>
                <li>della mia esperienza come volontaria di Equinozio OdV (associazione che si occupa di salvare, curare e riabilitare cavalli)</li>
                <li>di confronti con professionisti del settore veterinario </li>
              </ul>
            </Typo>
            <br/>
            <Typo type="h0" align="center" color="#ffffff" margin="5px">Attenzione</Typo>
            <Typo type="p" align="center" color="#ffffff">Queste saranno alcune delle cose che imparerai, e ce ne saranno molte altre.<br/>Ricorda però che <u>NON SARAI IN GRADO DI SOSTITUIRTI IN ALCUN MODO AL VETERINARIO</u>, né nella valutazione dei problemi, né nella risoluzione di essi.</Typo>
            <Typo type="p" align="center" color="#ffffff">Questo percorso ti darà le conoscenze per interpretare i segnali del cavallo e capire se e quando può essere necessario chiamare il veterinario (e di farlo per tempo, non troppo tardi come purtroppo spesso accade) e le competenze per intervenire nel modo in cui ti verrà da lui o lei indicato.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            {/* <Typo type="h3">In che modo diventa etico?</Typo>
            <Typo type="p">Tutti gli insegnamenti hanno alla base l’approccio cognitivo-relazionale e troveremo per ogni coppia umano-equino la modalità più adatta per apprendere le competenze necessarie e raggiungere ogni obiettivo.</Typo>            
        
            <hr/>        */}

            <Typo type="h3">Obiettivi del percorso</Typo>
            <Typo type="h4">Parte teorica</Typo>
            <Typo type="p">
              <ul>
                <li>Cenni di anatomia: imparare come è fatto il cavallo.</li>
                <li>Cenni di fisiologia: imparare come funziona il suo organismo.</li>
                <li>Trasferire le informazioni dalla linea teorica a quella pratica per imparare di che cosa ha bisogno il cavallo e perché.</li>
                <li>Quali sono le principali patologie in cui il cavallo può ritrovarsi coinvolto.</li>
              </ul>
            </Typo>             
            <br/>          
            <Typo type="h4">Parte pratica</Typo>
            <Typo type="p">
              <ul>
                <li>Imparare quali sono le cure quotidiane importanti per il tuo cavallo e come farle in modo più piacevole possibile per entrambi.</li>
                <li>Imparare a capire per tempo quali sono i segnali che possono indicare malessere nel tuo cavallo per poter intervenire tempestivamente.</li>
                <li>Imparare le manovre necessarie per curare il tuo cavallo in caso di problemi.</li>
                <li>Imparare ad insegnare al tuo cavallo a farsi curare e medicare in caso di qualsiasi necessità con calma e rilassamento.</li>
              </ul>
            </Typo>           
            <br/>            
            <Typo type="h4">Esempi di competenze che avrai acquisito alla fine del percorso</Typo>
            <Typo type="p">
              <ul>
                <li>Medicare una ferita al tuo cavallo nella maniera corretta.</li>
                <li>Fare una scarpetta.</li>
                <li>Mettere creme e colliri con la collaborazione del tuo cavallo.</li>
                <li>Fare una puntura per garantire antibiotico o antidolorifico al tuo cavallo.</li>
              </ul>
            </Typo>         
            <br/>     
            <Typo type="h0" margin="5px" color="#5E0B15">Attenzione</Typo>
            <Typo type="p"><u>NON SARAI IN GRADO DI SOSTITUIRTI IN ALCUN MODO AL VETERINARIO</u>, né nella valutazione dei problemi, né nella risoluzione di essi. Questo percorso ti darà le conoscenze per interpretare i segnali del cavallo e capire se e quando può essere necessario chiamare il veterinario (e di farlo per tempo, non troppo tardi come purtroppo spesso accade) e le competenze per intervenire nel modo in cui ti verrà da lui o lei indicato.</Typo>      

            <hr/>

            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Questo corso fa per te se...</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Hai un cavallo tuo, a casa in autogestione o in maneggio.</li>
                  <li>Hai un cavallo in fida o mezza fida.</li>
                  <li>Frequenti un maneggio prendendo lezioni insieme ad un cavallo del centro.</li>
                  <li>Lavori come stalliere, groom, gestore o istruttore presso un maneggio.</li>
                  <li>Sei volontario presso un'associazione che si occupa di cavalli.</li>
                  <li>Tu o il tuo cavallo avete il terrore del momento vermifugo o del momento vaccino, il tuo cavallo ha difficoltà a farsi curare o medicare da te o da altre persone.</li>
                  <li>Hai sempre rimandato il momento in cui insegnare al tuo cavallo a restare calmo durante una visita veterinaria o durante la somministrazione di medicine.</li>
                </ul>
              </Typo> 
            </Box>
            
            <hr/>

            <Typo type="h3">Cosa faremo durante le lezioni?</Typo>
            <Typo type="p">Il percorso comprende una parte teorica e una parte pratica, in cui verranno trattate parallelamente varie tematiche legate alla cura quotidiana e straordinaria del cavallo.</Typo>
            <Typo type="p">La parte teorica sarà supportata da slide e materiale didattico per ogni argomento. Non sarà svolta in forma di monologo descrittivo, bensì tramite dialogo con i partecipanti, che potranno esporre dubbi, domande ed esperienze per arricchirsi a vicenda.</Typo>
            <Typo type="p">Durante la parte pratica verrà tracciato, ad ogni lezione e per ogni argomento, un punto zero per ogni coppia di partecipanti, lavorando in gruppo per migliorare ognuno la propria preparazione a seconda delle capacità che già si possiedono.<br/>
            Ogni cavallo e ogni persona hanno fatto esperienze diverse nella vita; per questo, oltre al lavoro della persona con il proprio cavallo, ci saranno momenti di osservazione delle altre coppie e aiuto reciproco tra i partecipanti.</Typo>
            <Typo type="p">Tutti gli insegnamenti hanno alla base l’approccio cognitivo-relazionale e troveremo per ogni coppia umano-equino la modalità più adatta per apprendere le competenze necessarie e raggiungere ogni obiettivo.</Typo>  

          </Col>
          <Col widthmd={4}>

            <Box variant="primary"> 
              <Typo type="h3" color="#ffffff" align="center">Calendario</Typo>
              <Typo type="h0" color="#ffffff" align="center">Prossima data d'inizio</Typo>
              <Typo type="h3" color="#ffffff" align="center">-</Typo>

              <Typo type="h0" color="#ffffff" align="center">Impegno</Typo>
              <Typo type="note" color="#ffffff" align="center">Questo percorso richiede un impegno indicativamente di 1 volta al mese, per circa 3 ore.</Typo>             
              <Typo type="note" color="#ffffff" align="center">In questo modo, tra un incontro e l'altro avrete il tempo di lavorare con il vostro cavallo per fissare le competenze impostate ad ogni lezione. Avrete quindi la possibilità, all'incontro successivo, di porre domande, affrontare dubbi e difficoltà, e quindi lavorare per risolvere ogni problema.</Typo>             
            </Box>
            <img src="/photos/thumbnails/percorso-medicazioni.jpg" alt="Medicazioni " width="100%" />
            <Box variant="light">              
              <Typo type="h4" align="center">Prerequisiti per partecipare</Typo>
              <Typo type="note" align="center">Questo percorso è aperto a tutti, ma davvero a tutti, perché è importante che tutti abbiano la possibilità di imparare nozioni così fondamentali per prendersi cura del cavallo.<br/>
              Unico prerequisito per partecipare è la conoscenza delle basi della comunicazione col cavallo, in modo da potergli fare poche e semplici proposte e richieste, sapendo osservare e capire le sue risposte.</Typo>
              <Typo type="note" align="center">Fino all'inizio del corso c'è la possibilità di fare lezioni per arrivare ai requisiti minimi richiesti per la partecipazione!</Typo>             
            </Box>      
              
            
          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default PathsMedication;
