import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';


import AnalyticsService from './../../services/AnalyticsService';



import Section from '../layout/Section';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card'
import Typo from '../../common/components/Typo'
import Meta from './../layout/Meta';

import Layout from '../layout/Layout';


const ScrollTrigger = styled.div`
  height: 100%;
  cursor: pointer;
  transition: transform 50ms ease-in-out;

&:hover {
  transform: rotate(2deg);
}
`;
const ScrollRef = styled.div`
  scroll-margin: 85px;
`;

const Activities = ({layout}) => {
  let { lang } = useParams();
  
  const scrollToEquinofilia = useRef();
  const scrollToIDV = useRef();
  const scrollToPEE = useRef();
  const scrollToHorseTrail = useRef();
  const scrollToCavalliProblematici = useRef();
  const scrollToAbituazioneAllaSella = useRef();
  const scrollToDressage = useRef();
  const scrollToSaltoOstacoli = useRef();
  const scrollToVolteggio = useRef();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Attività" : "xxx"}
        languages={{"it": "activities"}}
      />
      <Section variant="primary" image={"/photos/headers/attivita.jpg"}>
        <Row>
          <Col widthmd={12}>  
            {/* <Typo type="h0" align="center" color="#ffffff">Attività</Typo> */}
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Attività" : "xxx"}</Typo>
            <Typo type="p" align="center" color="#ffffff">Le nostre attività possono essere intraprese in qualsiasi momento, semplicemente prenotando una lezione sull'argomento scelto. Le lezioni si terranno in gruppi di persone e cavalli con competenze affini.</Typo>
            <Typo type="p" align="center" color="#ffffff">&Egrave; possibile partecipare con un cavallo di proprietà o in fida (residente o meno presso il centro), o con un cavallo messo a disposizione dal centro.</Typo>

            
          </Col>
        </Row>
        <br/><br/>
        <Row>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToEquinofilia.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-equinofilia.jpg" alt="Equinofilia" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Equinofilia" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToIDV.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-IDV.jpg" alt="IDV" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Inizio del Viaggio" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToPEE.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-PPE.jpg" alt="PPE" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Preparazione alle Escursioni Etiche" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToHorseTrail.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-horse-trail.jpg" alt="Horse Trail" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Horse Trail" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToCavalliProblematici.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-cavalli-problematici.jpg" alt="Cavalli problematici" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Cavalli problematici" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToAbituazioneAllaSella.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-abituazione-sella.jpg" alt="Abituazione alla sella" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">Abituazione alla sella</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToDressage.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-dressage.jpg" alt="Dressage" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">Dressage</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToSaltoOstacoli.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-salto-ostacoli.jpg" alt="Salto Ostacoli" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">Salto Ostacoli</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToVolteggio.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/attivita-volteggio.jpg" alt="Volteggio" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">Volteggio</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
        </Row>
        <br/><br/>
      </Section>
      
      <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">A chi sono rivolte</Typo>
            <Typo type="p" align="left">
              <ul>
                <li><b>Neofiti e principianti</b>: persone che non hanno mai conosciuto i cavalli o hanno fatto solo qualche lezione</li>
                <li><b>Media esperienza</b>: persone che hanno già conosciuto i cavalli ma vogliono approfondire il mondo dell'Equitazione Etica</li>
                <li><b>Esperti</b>: persone che hanno già avuto esperienze di Equitazione Etica, o di Equitazione tradizionale ma che vogliono approfondire i temi trattati</li>
                <li><b>Proprietari e non</b>: possibilità di fare attività con il proprio cavallo o con uno del centro (in questo caso, dove opportuno, verrà affidato sempre lo stesso cavallo, in modo da conoscersi in modo sempre più approfondito e migliorare di volta in volta)</li>
                <li><b>Bambini, ragazzi e adulti</b>: ogni attività è pensata e formulata in base all'età e alle capacità dei partecipanti</li>
              </ul>
            </Typo>
            <Typo type="p" align="left">
              Tutte le attività prevedono una parte da terra e alcune una parte in sella, ma a seconda della preparazione del binomio o delle proprie preferenze è possibile svolgere tutto il percorso da terra, anche per le discipline che tipicamente vengono viste solo in sella.
            </Typo>
          </Col>
        </Row>
      </Section>


      <Section variant="light">
        <ScrollRef ref={scrollToEquinofilia} /> 
        <Row>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Equinofilia" : "xxx"}</Typo>
            <Typo type="p">Equinofilia significa letteralmente:</Typo>
            <ul>
              <li>Amore e passione per i cavalli</li>
              <li>Interesse al benessere fisico e psicologico</li>
              <li>Ricerca di una relazione etica umano-equino</li>
            </ul>
            <Typo type="p">&Egrave; un percorso per conoscere chi è il cavallo e di che cosa ha davvero bisogno, per conoscere i cavalli come specie, ma anche il cavallo come singolo individuo, per imparare a riconoscere le loro emozioni e a comunicare con loro.</Typo>
          </Col>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-equinofilia.jpg" alt="Equinofilia" width="100%" />
          </Col>
        </Row>
      </Section>      
      <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">{lang === "it" ? "Equitazione Etica" : "xxx"}</Typo>
            <Typo type="p">L'Equitazione Etica è un nuovo modo di fare equitazione, consapevole ed empatico, che riconosce umano e cavallo come due partner con la stessa importanza all'interno della relazione.</Typo>
            <Typo type="p">L'Equitazione Etica utilizza l'approccio cognitivo-relazionale stabilendo 3 principi cardine:</Typo>
            <ul>
              <li><b>Benessere</b>: sempre al primo posto, la gestione naturalizzata è l’unica che permette al cavallo di soddisfare tutti i suoi bisogni</li>
              <li><b>Mente</b>: come motore di ogni comportamento</li>
              <li><b>Alterità</b>: riconoscere il cavallo come soggetto diverso da noi e per questo come valore aggiunto alla relazione</li>
            </ul>
            <Typo type="p">&Egrave; un percorso per imparare a comprendere i cavalli e a comunicare con loro, con l'obiettivo di fare attività che siano piacevoli non solo per noi, ma anche per loro. Non solo da terra, ma anche in sella.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToIDV} /> 
        <Row>
          <Col widthmd={7}>  
            <Typo type="h2" margin="0" align="center">{lang === "it" ? "IDV" : "xxx"}</Typo>
            <Typo type="h3" align="center">Inizio del Viaggio</Typo>
            <Typo type="p">Il percorso per Appassionati di Equitazione Etica comincia con l’Inizio del Viaggio: fornisce conoscenze e sviluppa abilità, allargando ad ogni incontro il livello di esperienza di entrambi i partner, umano ed equino.</Typo>
            <Typo type="p">Intraprendendo questo viaggio imparerai ad osservare i cavalli al fine di comprendere il loro linguaggio del corpo, diventerai abile nel muoverti in sicurezza insieme a loro e riuscirai a comunicare sia da terra che in sella, instaurando un vero e proprio dialogo.</Typo>
            <Typo type="p">Grazie a questo percorso ti verrà rilasciata l'Abilitazione A.</Typo>
          </Col>
          <Col widthmd={5}>  
            <center>
              <img src="/photos/thumbnails/attivita-IDV.jpg" alt="IDV" width="100%" />
            </center>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToPEE} /> 
        <Row>
          <Col widthmd={5}>  
            <center>
              <img src="/photos/thumbnails/attivita-PPE.jpg" alt="PPE" width="100%" />
            </center>
          </Col>
          <Col widthmd={7}>  
            <Typo type="h2" margin="0" align="center">{lang === "it" ? "PPE" : "xxx"}</Typo>
            <Typo type="h3" align="center">Preparazione alle Escursioni Etiche</Typo>
            <Typo type="p">Raggiunto un buon equilibrio e una discreta capacità di comunicazione, potrai prepararti ad affrontare passeggiate ed escursioni insieme a cavalli condotti in capezza e scoprirai quanto queste attività possano essere appaganti sia per te che per il cavallo. La vostra relazione si rafforzerà sempre di più, mentre affronterete e supererete le sfide che presenta il mondo esterno.</Typo>
            <Typo type="p">Grazie a questo percorso ti verrà rilasciata l'Abilitazione B.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToHorseTrail} /> 
        <Row>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Horse Trail" : "xxx"}</Typo>
            <Typo type="p">L'Horse Trail è un percorso fatto da vari tipi di ostacoli (per lo più naturali) da scavalcare, attraversare, su cui salire o sotto ai quali passare.</Typo>
            <Typo type="p">Qui non si ricerca la velocità o la perfezione dell'esercizio, ma si osserva la comunicazione e l'intesa tra cavallo e umano: se si capiscono, se entrambi sono ben disposti, se si aspettano, se si fidano l'uno dell'altro.</Typo>
            <Typo type="p">Allenandovi in questa disciplina potrete raffinare la vostra comunicazione e scoprire sempre nuovi ostacoli da superare insieme!</Typo>
          </Col>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-horse-trail.jpg" alt="Horse Trail" width="100%" />
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToCavalliProblematici} /> 
        <Row>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-cavalli-problematici.jpg" alt="Educazione e rieducazione di puledri e cavalli con problemi" width="100%" />
          </Col>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Educazione e rieducazione di puledri e cavalli con problemi" : "xxx"}</Typo>
            <Typo type="p">Se tra te e il tuo cavallo ci sono delle incomprensioni, possiamo capire come risolverle per sempre: senza coprire o nascondere temporaneamente le difese o le difficoltà, ma capendone l’origine e andando a trovare una soluzione per la causa del problema.<br/>Ricorda che se un cavallo calcia, morde, impenna, si arrabbia, si rifiuta, ecc. è perché sta esprimendo un disagio fisico e/o psicologico: va compreso e ascoltato, non addestrato!<br/>Una volta capito il reale problema, potremo aiutarlo a risolverlo.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToAbituazioneAllaSella} /> 
        <Row>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Abituazione e ri-abituazione alla sella" : "xxx"}</Typo>
            <Typo type="p">
              Il programma di abituazione e ri-abituazione alla sella è pensato per puledri e cavalli che non hanno mai visto una sella, che hanno avuto brutte esperienze in passato con la sella o in generale che si infastidiscono, si muovono, schiacciano le orecchie, mordono, calciano o si arrabbiano quando vengono sellati.<br/>
              Se tu e il tuo puledro o cavallo avete bisogno di un qualsiasi aiuto riguardo l'argomento sella, questo programma fa per voi!
            </Typo>
          </Col>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-abituazione-sella.jpg" alt="Abituazione alla sella" width="100%" />
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">{lang === "it" ? "Discipline trasformate" : "xxx"}</Typo>
            <Typo type="p">Alla base dell'Equitazione Etica c'è l'Approccio Cognitivo, per questo per intraprendere alcune discipline dell'equitazione tradizionale è necessario trasformarle apportando qualche modifica.</Typo>
            <Typo type="p">Per prima cosa, non utilizziamo nessuno strumento coercitivo per il cavallo, come ad esempio imboccature e speroni.<br/>
            Per la nostra comunicazione non utilizziamo la forza nè le scomodità, ma il coinvolgimento e la collaborazione.</Typo>
            <Typo type="p">Ogni esercizio viene insegnato al cavallo tramite un processo di apprendimento graduale ed accurato e tutti i cavalli vengono preparati fisicamente e mentalmente all'attività che gli proporremo.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <ScrollRef ref={scrollToDressage} /> 
        <Row>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-dressage.jpg" alt="Dressage" width="100%" />
          </Col>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Dressage" : "xxx"}</Typo>
            <Typo type="p">Il dressage è una serie di esercizi, figure e movimenti in campo da svolgere in successione.</Typo>
            <Typo type="p">Ottima sfida per testare la precisione della comunicazione con il cavallo e la vostra intesa, si può sempre migliorare!</Typo>
            <Typo type="p">&Egrave; anche un validissimo allenamento per mantenere la forma fisica!</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <ScrollRef ref={scrollToSaltoOstacoli} /> 
        <Row>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Salto Ostacoli" : "xxx"}</Typo>
            <Typo type="p">Il salto ostacoli è un percorso di salti, diverso per altezza e difficoltà a seconda della categoria.</Typo>
            <Typo type="p">Restando nel range di possibilità fisiche e mentali del cavallo può essere un ottimo esercizio di ginnastica e di consapevolezza del proprio corpo, sia per il cavallo che per il cavaliere. Allenamento non solo del fisico, a cui serve una corretta ginnastica, ma anche della comunicazione, per renderla chiara e precisa.</Typo>
          </Col>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-salto-ostacoli.jpg" alt="Salto Ostacoli" width="100%" />
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <ScrollRef ref={scrollToVolteggio} /> 
        <Row>
          <Col widthmd={5}>  
            <img src="/photos/thumbnails/attivita-volteggio.jpg" alt="Volteggio" width="100%" />
          </Col>
          <Col widthmd={7}>  
            <Typo type="h2" align="center">{lang === "it" ? "Volteggio" : "xxx"}</Typo>
            <Typo type="p">Il volteggio è una specialità che consiste nell'eseguire esercizi ginnici a corpo libero sul cavallo condotto da terra da un'altra persona.</Typo>
            <Typo type="p">Con delicatezza e senza salti che possano nuocere alla schiena del cavallo, il volteggio diventa etico anche per il fatto che il cavallo cammina insieme ad una persona che comunica costantemente con lui con un dialogo, variando i percorsi e le velocità, senza mai costringere il cavallo in nessun modo.</Typo>
          </Col>
        </Row>
      </Section>
      


      
    </Layout>
  )
}

export default Activities;
