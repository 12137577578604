import React, {useEffect} from 'react'
import {Helmet} from "react-helmet";

import AnalyticsService from './../../services/AnalyticsService';

import Layout from './../layout/Layout';
import Meta from './../layout/Meta';
import Typo from '../../common/components/Typo';
import Section from './../layout/Section';

const NotFoundPage = ({layout}) => {

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return ( 
    <>
      <Meta
        title={"404"}
        description={"TODO"}
        image={"https://www.martinapassanante.it/photos/header.jpg"}
      />
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <Layout layout={layout}>
        <Section variant="white">
          <Typo type="h0" align="center">404</Typo>
          <Typo type="h1" align="center">Not Found</Typo>
        </Section>
      </Layout>
    </>
  )
}

export default NotFoundPage
