import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import StorageService from '../../services/StorageService';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  line-height: ${props => props.size};
  margin: ${props => props.margin};

  &, & * {
    box-sizing: border-box;
  }
`;
// const Image  = styled.div`
//   position: absolute;
//   top: 0%;
//   left: 0%;
//   width: 100%;
//   height: 100%;
//   background-color: ${props => props.color};
//   mask: url(${props => props.icon}) no-repeat 50% 50%;
//   mask-size: 100% 100%;

//   &:hover{
//     background-color: ${props => props.hoverColor};
//   }
// `;




const Icon = ({
  name = "default",
  color = "#000000",
  size = "20px",
  margin = "0px"
}) => {
  const [url, setUrl] = useState([]);

  useEffect(() => {
    StorageService.getUrl("/icons/"+name+".svg").then((response) => {
      if(response.success){
        setUrl(response.url);
      }else{
        console.log(response.error);
      }
    });
  }, [name]);

  return (
    <Wrapper size={size} margin={margin}>
      <img width="100%" src={url ? url : null} alt={""}  />
      {/*<Image
        icon={url ? url : null}
        color={color}
        alt={name}
      />*/}
    </Wrapper>
  )
}

export default Icon
