import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const PathsMedication = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Trailer Loading - Percorsi" : "xxx"}
        languages={{"it": "paths/trailer-loading"}}
      />
      <Section variant="primary" image={"/photos/headers/percorso-trailer-loading.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Percorso</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Trailer Loading" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">Il Trailer Loading è un percorso in cui insegneremo al cavallo, non solo il "loading" (ovvero a salire su van o trailer), ma anche a viaggiare.</Typo>
            <Typo type="p" color="#ffffff">Può sembrare scontato, perché chissà quanti viaggi avrà già fatto il tuo cavallo, ma come li ha vissuti?<br/>
            Dopo ogni viaggio scende dal trailer sudato e agitato? Con questo percorso lo aiuteremo a salire con tranquillità, a non aver paura di restare nel van e ad avere un'aspettativa positiva di dove si andrà!</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            <Typo type="h3">In che modo diventa etico?</Typo>
            <Typo type="p">Utilizzando l'approccio cognitivo-relazionale, non utilizzeremo il concetto di comodità e scomodità per far salire il cavallo sul van: il cavallo non verrà messo "scomodo" fuori dal trailer e "comodo" al suo interno.<br/>
            Il cavallo sarà sempre calmo e cognitivo, verrà supportato quando si ricorderà delle esperienze passate che magari non saranno state sempre positive, e incentivato lavorando anche sull'aspettativa e la compagnia di altri cavalli e umani.</Typo>            
        
            <hr/>

            <Typo type="h3">Obiettivi del percorso</Typo>
            <Typo type="p">
              <ul>
                <li>Imparare a mantenere calma e concentrazione anche in momenti più difficili e diversi dal solito, e successivamente, insegnarlo anche al tuo cavallo.</li>
                <li>Uscire dalla vostra zona di comfort, facendo esperienze nuove e diverse dal solito.</li>
                <li>Sviluppare e migliorare la fiducia tra te e il tuo cavallo.</li>
                <li>Diventare indipendenti al 100% con il proprio cavallo (o un cavallo del centro) anche nell'ambito dei viaggi.</li>
                <li>Insegnare al cavallo, non solo a salire sul van o trailer, ma anche a viaggiare in sicurezza, con calma e con un'aspettativa positiva.</li>
              </ul>
            </Typo>  

            <hr/>

            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Questo corso fa per te se...</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Non vedi l'ora di partire per un viaggio (mare o montagna?) con il tuo cavallo, ma vuoi prepararti come si deve per assicurarti che sia un'esperienza positiva anche per il tuo cavallo.</li>
                  <li>Il tuo cavallo ha difficoltà a salire sul van o trailer e a viaggiare, o se ha brutti ricordi legati ad esperienze passate, e tu vuoi fare qualcosa per aiutarlo.</li>
                  <li>Vuoi metterti alla prova insieme al tuo cavallo o ad un cavallo del centro per esplorare nuove attività e nuovi paesaggi.</li>
                </ul>
              </Typo> 
            </Box>
            
            <hr/>

            <Typo type="h3">Cosa faremo durante le lezioni?</Typo>
            <Typo type="p">
              <ul>
                <li>Ripasso delle conoscenze e competenze fondamentali per intraprendere i viaggi con i cavalli.</li>
                <li>Merenda per i cavalli sopra al van.</li>
                <li>Salire, scendere, e prove di piccoli spostamenti in van.</li>
                <li>Prova delle varie coppie di cavalli che possono viaggiare insieme.</li>
                <li>Ogni cavallo e ogni persona ha il proprio bagaglio di esperienza, sarà quindi fondamentale non solo esercitarsi con il proprio cavallo ma anche osservare il lavoro degli altri partecipanti per conoscersi sempre meglio e imparare modi diversi di proporre la stessa attività.</li>
              </ul>
            </Typo>

          </Col>
          <Col widthmd={4}>

            <Box variant="primary"> 
              <Typo type="h3" color="#ffffff" align="center">Calendario</Typo>
              <Typo type="h0" color="#ffffff" align="center">Prossima data d'inizio</Typo>
              <Typo type="h3" color="#ffffff" align="center">-</Typo>

              <Typo type="h0" color="#ffffff" align="center">Impegno</Typo>
              <Typo type="note" color="#ffffff" align="center">Questo percorso richiede un impegno indicativamente di 1 volta al mese, per circa 3 ore.</Typo>             
              <Typo type="note" color="#ffffff" align="center">In questo modo, tra un incontro e l'altro avrete il tempo di lavorare con il vostro cavallo per fissare le competenze impostate ad ogni lezione. Avrete quindi la possibilità, all'incontro successivo, di porre domande, affrontare dubbi e difficoltà, e quindi lavorare per risolvere ogni problema.</Typo>             
            </Box>
            <img src="/photos/thumbnails/percorso-trailer-loading.jpg" alt="Trailer Loading " width="100%" />
            <Box variant="light">              
              <Typo type="h4" align="center">Prerequisiti per partecipare</Typo>
              <Typo type="note" align="center">Essere in possesso dell'abilitazione A, per saper comunicare e relazionarsi nel modo corretto con il proprio cavallo.</Typo>
              <Typo type="note" align="center">Tanta voglia di imparare, provando e osservando, restando aperti all’ascolto dell'altro.</Typo>             
              <Typo type="note" align="center">Fino all'inizio del corso c'è la possibilità di fare lezioni per arrivare ai requisiti minimi richiesti per la partecipazione!</Typo>             
            </Box>      
              
            
          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default PathsMedication;
