//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';
import styled from 'styled-components';

import {OverlayContext} from '../../contexts/OverlayContext';

import DatabaseService from '../../services/DatabaseService';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';

import {testValidField} from '../../helpers';


const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;

const InsiderHorsesModalNew = () => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
		"isCorrectBarncodeInBDN": 0,
		"isCorrectOwner": 0,
		"isDPA": 0,
		"isDeceased": -1,
		"isHere": 1,
		"isInBDN": 0
	});

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
	
  const save = () => {
		DatabaseService.addHorse(item).then((response) => {
			console.log(response)
			window.location.replace("/admin/horses/");
        // navigate("/admin/horses/"+response.slug);
    });

  };

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(item.name, true)){
        valid = false;
      }
      if(!testValidField(item.docname, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  return (
    <Container size="small">
      <Card
				headerLeft={
					"Nuovo cavallo"
				}
				headerSeparator={true}
				footerLeft={<Button variant={"default-o"} onClick={() => setOverlay(null)}>Indietro</Button>}
				footerRight={<Button onClick={() => save()} disabled={disabled}>Salva</Button>}
			>

				<Row>
					
					<Col widthmd="12">
						<Input
							label="Nome"
							width="100%"
							value={item.name}
							onChange={(val) => {
								let tempItem = {...item};
								tempItem.name = val;
								setItem(tempItem);
							}}
							required
						/>
					</Col>
					<Col widthmd="12">
						<Input
							label="Nome sul documento"
							width="100%"
							value={item.docname}
							onChange={(val) => {
								let tempItem = {...item};
								tempItem.docname = val;
								setItem(tempItem);
							}}
							required
						/>
					</Col>
					<Col widthmd="12">
						<Select
							label="In struttura"
							width="100%"
							searchable={false}
							values={[
								{name: "", value: 0},
								{name: "Sì", value: 1},
								{name: "No", value: -1}
							]}
							selected={item.isHere}
							onChange={(val) => {
								let tempItem = {...item};
								tempItem.isHere = val;
								setItem(tempItem);
							}}
						/>
					</Col>
					
				</Row>
				<ErrorMessage>
					{errorMessage}
				</ErrorMessage>				
			
			</Card>
		</Container>
  );

}
export default InsiderHorsesModalNew;
