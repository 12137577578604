import React  from 'react';
import {Link, NavLink} from 'react-router-dom';
/*import React, {useState, useEffect, useCallback}  from 'react';
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom';*/
import styled from 'styled-components';

import Logo from './../../common/components/Logo';
// import Icon from './../../common/components/Icon';

const Wrapper = styled.div`
  *{
    text-decoration: none !important;
  }
`;
const Top = styled.div`
  height: 140px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const Content = styled.div`
  padding: 20px;
`;

const Item = styled.div`
  font-family: 'Young Serif',sans-serif;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-decoration: none !important;

  & > *{
    color: ${props => props.isActive ? "#5E0B15" : "#ffffff"};
  }
  background: ${props => props.isActive ? "#ffffff" : "transparent"};

  &:hover{
    & > *{
      color: #5E0B15;
    }
    background: #EDDDD4;
  }
`;
// const IconWrapper = styled.div`
//   display: inline-grid;
//   margin-right: 8px;
// `;
const Text = styled.div`
  font-size: bold;
  font-size: 18px;
`;

/*
const SelectProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const SelectProjectIcon = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 6px;
  margin-left: -12px;
`;
const SelectProjectText = styled.div`
`;
const SelectProjectName = styled.div`
  font-weight: 600;
  font-size: 12px;
`;
const SelectProjectUuid = styled.div`
  font-weight: 400;
  font-size: 10px;
`;
*/

const Sidebar = () => {

  /*
  const navigate = useNavigate();
  const { projectUuid } = useParams();

  const [currentProject, setCurrentProject] = useState(projectUuid ? projectUuid : null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    ProjectsService.getAll().then((response) => {
      if(response.success){
        setProjects(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, []);

  const changeProject = useCallback((val) => {
    setCurrentProject(val.value);
    navigate("/"+val.value+"/dashboard");
  }, [navigate]);

 */
  return (
    <Wrapper>
      <Top>
        <Link to="/admin">
          <Logo variant="white" height="100px" />
        </Link>
      </Top>
      <Content>

        <Wrapper>
          <NavLink to={"/admin/"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                {/* <IconWrapper>
                  <Icon name={"dashboard"} color={"#ffffff"} size="25px" />
                </IconWrapper> */}
                <Text>
                  Dashboard
                </Text>
              </Item>
            )}
          </NavLink>
          <NavLink to={"/admin/horses"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                {/* <IconWrapper>
                  <Icon name={"horses"} color={"#ffffff"} size="25px" />
                </IconWrapper> */}
                <Text>
                  Cavalli
                </Text>
              </Item>
            )}
          </NavLink>
          <NavLink to={"/admin/memberships"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                {/* <IconWrapper>
                  <Icon name={"members"} color={"#ffffff"} size="25px" />
                </IconWrapper> */}
                <Text>
                  Tesseramenti
                </Text>
              </Item>
            )}
          </NavLink>
          {/* <NavLink to={"/admin/events"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                <IconWrapper>
                  <Icon name={"events"} color={"#ffffff"} size="25px" />
                </IconWrapper>
                <Text>
                  Eventi
                </Text>
              </Item>
            )}
          </NavLink>
          <NavLink to={"/admin/adoption-applications"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                <IconWrapper>
                  <Icon name={"events"} color={"#ffffff"} size="25px" />
                </IconWrapper>
                <Text>
                  Candidature adozione
                </Text>
              </Item>
            )}
          </NavLink>
          <NavLink to={"/admin/cession-applications"} exact="true">
            {({ isActive }) => (
              <Item isActive={isActive}>
                <IconWrapper>
                  <Icon name={"events"} color={"#ffffff"} size="25px" />
                </IconWrapper>
                <Text>
                  Candidature cessione
                </Text>
              </Item>
            )}
          </NavLink> */}

        </Wrapper>

      </Content>
    </Wrapper>
  )
}

export default Sidebar;
