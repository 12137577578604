import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';
import DatabaseService from '../../services/DatabaseService';



import Section from '../layout/Section';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Checkbox from '../../common/components/Checkbox';

import {testValidField} from '../../helpers';

const ErrorMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: red;
  text-align: center;
`;

const SuccessMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: green;
  text-align: center;
`;

const NuovavitaMembership = ({layout}) => {
  let { lang } = useParams();
  const [item, setItem] = useState({
    year: new Date().getFullYear(),
    hasPaid: -1,
    isSubmitted: -1,
    hasCard: -1
  });
  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(!testValidField(item.givenName, true)){
      valid = false;
    }
    if(!testValidField(item.familyName, true)){
      valid = false;
    }
    if(!testValidField(item.email, true)){
      valid = false;
    }
    if(!testValidField(item.taxCode, true)){
      valid = false;
    }
    if(!testValidField(item.birthDate, true)){
      valid = false;
    }
    if(!testValidField(item.birthPlace, true)){
      valid = false;
    }
    if(!testValidField(item.city, true)){
      valid = false;
    }
    if(!testValidField(item.zip, true)){
      valid = false;
    }
    if(!testValidField(item.address, true)){
      valid = false;
    }
    if(!testValidField(item.paymentConfirmation, true)){
      valid = false;
    }
    
    setDisabled(!valid);
  }, [item]);

  const send = useCallback(() => {
    setErrorMessage("");
    item.date = Date.now();
    DatabaseService.addMembership(item).then((response) => {
      if(response.success){
        setSuccessMessage("Richiesta inviata correttamente!");
        setItem({});
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [item]);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Tesseramento" : "Membership"}
        languages={{"it": "muova-vita/membership"}}
      />
      <Section variant="primary" image={"/photos/headers/nuovavita-tesseramento.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Nuova Vita</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Tesseramento" : "Membership"}</Typo>
            <Typo type="p" align="center" color="#ffffff">
              Il tesseramento è obbligatorio per usufruire dei servizi e attività dell'ASD.<br/>
              All'interno della tessera dell'ASD è compresa l'assicurazione con Asi, ente di promozione sportiva riconosciuto dal CONI. Con essa si può usufruire dei servizi di tutte le ASD affiliate Asi.
            </Typo>          
          </Col>
        </Row>
      </Section>
      
      <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">Tesseramento per l'anno {new Date().getFullYear()}</Typo>
            <Typo type="p" align="left">
              Il tesseramento ha un costo annuale di €50 ed ha validità dal momento dell'attivazione fino al 31/12/{new Date().getFullYear()}.<br/>
              Per completare il tesseramento, effettuare il pagamento mezzo bonifico e compilare il modulo seguente.<br/><br/>
              Estremi per il pagamento:
              <ul>
                <li><b>IBAN</b>: IT50V0103057460000010294285</li>
                <li><b>Intestato a</b>: Nuova Vita Associazione Sportiva Dilettantistica</li>
                <li><b>Causale</b>: Quota Associativa (nome e cognome dell'associato) {new Date().getFullYear()}</li>
                <li><b>Importo</b>: €50</li>
              </ul>
            </Typo>
          </Col>
        </Row>
        <Row>
          <Col widthmd={6}>
            <Input
              label="Nome"
              width="100%"
              value={item.givenName}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.givenName = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Cognome"
              width="100%"
              value={item.familyName}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.familyName = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Email"
              width="100%"
              value={item.email}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.email = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Codice fiscale"
              width="100%"
              value={item.taxCode}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.taxCode = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Data di nascita"
              width="100%"
              value={item.birthDate}
              type="date"
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.birthDate = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Luogo di nascita"
              width="100%"
              value={item.birthPlace}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.birthPlace = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Comune di residenza"
              width="100%"
              value={item.city}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.city = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="CAP di residenza"
              width="100%"
              value={item.zip}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.zip = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col widthmd={6}>
            <Input
              label="Indirizzo di residenza"
              width="100%"
              value={item.address}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.address = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>   
          <Col widthmd={6}>
            <Checkbox
              label="Ho effettuato il bonifico"
              width="100%"
              selected={item.paymentConfirmation}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.paymentConfirmation = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>         
        </Row>

        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>

        <SuccessMessage>
          {successMessage}
        </SuccessMessage>

        <center>
          <Button 
            onClick={() => send()} 
            disabled={disabled}
          >Completa tesseramento</Button>
        </center>
      </Section>
      
    </Layout>
  )
}

export default NuovavitaMembership;
