import React from 'react';
import styled from 'styled-components';
import Check from "./Check.js";

const Wrapper = styled.div`
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  border
`;


const FakeLabel = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 300;
`;
const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
`;
const Description = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: gray;
  // margin-left: 28px;
`;



const Checkbox = ({
  variant = "default",
  label,
  description,
  selected = false,
  onChange,
  disabled = false,
  required = false
}) => {

  return (
    <Wrapper>
      <FakeLabel>&nbsp;</FakeLabel>
      <Row>
        <Check
          variant={variant}
          disabled={disabled}
          selected={selected}
          onChange={() => {
            if(!disabled){
              if(onChange){
                onChange(!selected);
              }
            }
          }}
        />
        {label ?
          <Label>
            {label} {required ? "*" : ""}
          </Label>
        : null}
      </Row>
      {description ?
        <Description>
          {description}
        </Description>
      : null }
    </Wrapper>
  )
}

export default Checkbox;
