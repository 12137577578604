import React, {useContext}  from 'react';
import {Link} from 'react-router-dom';
import {UserContext} from './../../contexts/UserContext';
import styled from 'styled-components';

import Container from './../../common/components/Container';
import Icon from './../../common/components/Icon';


const Wrapper = styled.div`
  background: #ffffff;
  height: 80px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

const Left = styled.div`
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`;

const Name = styled.div`
  display: flex;
  cursor: pointer;
`;
const DropdownIcon = styled.div`
  position: relative;
  bottom: -1px;
  margin-left: 10px;
  cursor: pointer;
`;
const Avatar = styled.div`
  border: 2px solid #303030;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #303030;
`;

const MenuTrigger = styled.div`
  position: relative;

  &:hover > *:last-child{
  max-height: 300px;
  }
`;
const MenuDropdown = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  max-height: 0px;
  overflow: hidden;
  min-width: 100%;
  transition: all 0.12s linear;
  cursor: pointer;
`;
const MenuWrapper = styled.div`
  margin-top: 50px;
  padding: 15px 15px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(68,102,242,0.05);
  cursor: default;
`;
const MenuItem = styled.div`
  padding: 7px 5px;
  & a{
    display: flex;
    color: inherit;
  }

  &:hover{
    color: red
  }
`;
const MenuIcon = styled.div`
  position: relative;
  bottom: -1px;
  margin-right: 8px;
`;
// const MenuSpacer = styled.div`
//   padding-bottom: 12px;
//   border-bottom: 1px solid #70707033;
//   margin-bottom: 7px;
// `;



const Header = ({callbackTogglerMenu}) => {
  const {user} = useContext(UserContext);

  // const [user, setuser] = useState(null);
  //
  // useEffect(() => {
  //   AuthService.get().then((response) => {
  //     if(response.success){
  //       setuser(response.data);
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, [setuser]);

  return (
    <Wrapper>
      <Container size="fluid">
        <Content>
          <Left>
            <div onClick={callbackTogglerMenu}>
              <Icon name={"menu"} color={"#303030"} size="30px"/>
            </div>
          </Left>
          <Right>
            {user ?
              <>
                <MenuTrigger>
                  <Name>
                    {user.given_name && user.family_name ?
                      user.given_name + " " + user.family_name
                    :
                      user.email
                    } <DropdownIcon><Icon color={"#303030"} size="12px" name={"arrow-down"} /></DropdownIcon>
                  </Name>
                  <MenuDropdown>
                    <MenuWrapper>
                      {/*<MenuItem>
                        <Link to="/admin/account"><MenuIcon><Icon name={"account"} color={"#303030"} size="16px" /></MenuIcon> Account</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/settings"><MenuIcon><Icon name={"settings"} color={"#303030"} size="16px" /></MenuIcon> Settings</Link>
                      </MenuItem>
                      <MenuSpacer />*/}
                      <MenuItem>
                        <Link to="/admin/login"><MenuIcon><Icon name={"logout"} color={"#303030"} size="16px" /></MenuIcon> Sign out</Link>
                      </MenuItem>
                    </MenuWrapper>
                  </MenuDropdown>
                </MenuTrigger>
                {/*<Link to='/admin/account'>
                  <Avatar>
                    {user.given_name && user.given_name.length > 0 ? user.given_name[0] : "?"}
                  </Avatar>
                </Link>*/}
              </>
            : null }
          </Right>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Header;
