import React from 'react'
import styled from 'styled-components'

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';

import Logo from './../../common/components/Logo';
// import Button from './../../common/components/Button';
import Socials from './Socials';

const Wrapper = styled.div`
  background-color: #254441;
  color: #ffffff;
  padding: 60px 0px 60px;
`;
const Top = styled.div`
  padding-bottom: 40px;
  font-size: 15px;
  text-align: center;
`;
// const MandalaLeft = styled.div`
//   opacity: 50%;
//   -webkit-transform: scaleX(-1);
//   transform: scaleX(-1);
// `;
// const MandalaRight = styled.div`
//   opacity: 50%;
// `;
const Bottom = styled.div`
  text-align: center;
  font-size: 12px;
`;
// const Developer = styled.a`
//   color: #ffffff;
//   text-decoration: none;
//   font-weight: bold;

//   &:hover{
//     color: #ffffff;
//     text-decoration: underline;
//   }
// `;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Top>
          <Row>
            <Col widthmd={4}>
              {/* <MandalaLeft>
                <img width={"80%"} src={process.env.PUBLIC_URL + "/mandala.png"} alt="Decorazione" />
              </MandalaLeft>
              <br/>
              <Button>Scopri gli eventi</Button> */}
            </Col>
            <Col widthmd={4}>
              <center>
                <Logo width="160px" variant="white" /><br /><br/>
              </center>
              <b>Martina Passanante</b><br/>
              <b>&</b><br/>
              <b>Nuova Vita ASD</b><br/><br/>
              Via Argine Boccadiganda 115,<br/>
              Borgo Virgilio 46034 (MN)<br/>
              Italy<br/><br/>
              +39 346 1330641<br/><br/>
              info@martinapassanante.it<br/><br/>
              <Socials inverse />
            </Col>
            <Col widthmd={4}>
              {/* <MandalaRight>
                <img width={"80%"} src={process.env.PUBLIC_URL + "/mandala.png"} alt="Decorazione" />
              </MandalaRight>
              <br/>
              <Button>Leggi gli articoli</Button> */}
            </Col>
          </Row>
        </Top>
        <Bottom>©{(new Date().getFullYear())} Martina Passanante{/* by <Developer href="https://www.narcolepticvolcano.it" target="_blank">narcolepticvolcano</Developer>*/}</Bottom>
      </Container>
    </Wrapper>
  )
}

export default Footer
