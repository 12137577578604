import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const PathsOutdoorExcursions = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Escursione Esterna - Percorsi" : "xxx"}
        languages={{"it": "paths/outdoor-excursion"}}
      />
      <Section variant="primary" image={"/photos/headers/percorso-escursione-esterna.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Percorso</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Escursione Esterna" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">Nel percorso Escursione Esterna si esplora l'ambiente esterno al centro insieme ai cavalli, uscendo in passeggiata.</Typo>
            <Typo type="p" color="#ffffff">L'escursione è una camminata in natura, a piedi e/o in sella, di durata variabile. Si esce in gruppi misti di cavalli e umani, entrambi dalle età ed esperienze varie, anche cani e altri animali sono ben accetti.<br/>
            Offriamo ai cavalli la possibilità di fare attività che in natura praticherebbero per gran parte del tempo mentre nella gestione domestica, seppur naturalizzata, sono limitate; le escursioni sono attività che allineano le motivazioni di specie equina ed umana.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            <Typo type="h3">In che modo diventa etico?</Typo>
            <Typo type="p">
              <ul>
                <li>La sicurezza ed il benessere di tutti i partecipanti sono messe sempre al primo posto.</li>
                <li>I cavalli partecipanti sono socializzati: l’escursione etica simula l'attività di spostamento di un branco che viva allo stato naturale.</li>
                <li>L'andatura più utilizzata, così come avverrebbe in natura, è il passo.</li>
                <li>Sono "iron-free" (senza ferri ai piedi, né in bocca).</li>
                <li>Non viene utilizzato nessuno strumento coercitivo (frustini, speroni, ecc).</li>
                <li>Sono sempre previste pause per recuperare, rilassarsi, godere dell'ambiente e del paesaggio e brucare con calma.</li>
              </ul>
            </Typo>            
        
            <hr/>

            <Typo type="h3">Obiettivi del percorso</Typo>
            <Typo type="p">
              <ul>
                <li>Composizione e crescita di un bel gruppo di umani ed equini che collaborano e si ascoltano, che si aiutano e si mettono alla prova.</li>
                <li>Essere in grado di uscire in passeggiata in sicurezza, incentivando la calma e la curiosità del cavallo, migliorando la relazione con lui.</li>
                <li>Le escursioni sono un momento di svago e di scoperta per tutti e per ciascuno con le proprie peculiarità (sono un’occasione per scoprire le differenze di specie e per appagare le motivazioni).</li>
                <li>Le escursioni permettono di far emergere e valorizzare le risorse e qualità individuali.</li>
              </ul>
            </Typo>  

            <hr/>

            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Questo corso fa per te se...</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Non vedi l'ora di partire per nuove esplorazioni insieme al tuo cavallo.</li>
                  <li>Vuoi approfondire la comunicazione e la relazione con il tuo cavallo in un contesto del tutto nuovo.</li>
                  <li>Ti piacerebbe fare passeggiate a cavallo rilassanti, preparandoti al meglio per questa attività.</li>
                  <li>Hai già fatto trekking e passeggiate, ma ti sei sentito sopraffatto perchè non pronto per quella situazione o annoiato perchè i cavalli sapevano già dove, come e quando andare e non avevi nessuna comunicazione con loro.</li>
                </ul>
              </Typo> 
            </Box>
            
            <hr/>

            <Typo type="h3">Cosa faremo durante le lezioni?</Typo>
            <Typo type="p">
              <ul>
                <li>Preparazione del gruppo ai possibili imprevisti che si possono trovare all'esterno.</li>
                <li>Esercizi di comunicazione col cavallo, tra i cavalli e tra le persone.</li>
                <li>Passeggiate in esterna graduali, mettendo a proprio agio tutti i partecipanti umani ed equini.</li>
                <li>Di fronte ad un nuovo ostacolo, difficile da superare per alcuni soggetti, faranno strada gli individui più esperti e coraggiosi, dando così il buon esempio, evitando nel modo più assoluto di spingere o costringere gli individui più incerti, così da scongiurare ogni sorta di disagio se non addirittura di trauma.</li>
                <li>Alle escursioni possono partecipare anche cavalli giovani ed inesperti, seguiti dal loro referente educativo (umano e/o equino), in modo tale da fare esperienza nel modo più graduale possibile.</li>
                <li>Esercizi di gruppo per imparare a comunicare non solo con il nostro cavallo ma anche con il resto del gruppo di umani.</li>
                <li>Esercizi e percorsi sia da terra che, per chi lo desidera, in sella.</li>
              </ul>
            </Typo>

          </Col>
          <Col widthmd={4}>

            <Box variant="primary"> 
              <Typo type="h3" color="#ffffff" align="center">Calendario</Typo>
              {/* <Typo type="h0" color="#ffffff" align="center">Prossima data d'inizio</Typo>
              <Typo type="h3" color="#ffffff" align="center">10 marzo 2024</Typo> */}

              <Typo type="h0" color="#ffffff" align="center">Date</Typo>
              <Typo type="p" color="#ffffff" align="center">
                <b>1°</b> - 16 marzo 2024 - 09:00-12:00<br/>
                <b>2°</b> - 21 aprile 2024 - 09:00-12:00<br/>
                <b>3°</b> - 5 maggio 2024 - 09:00-12:00<br/>
                <b>4°</b> - 23 giugno 2024 - 09:00-12:00<br/>
                <b>Rec:</b> - Da definire<br/>
                <b>5°</b> - Da definire<br/>
                <b>6°</b> - Da definire<br/>
                <b>7°</b> - Da definire<br/>
                <b>8°</b> - Da definire<br/>
                <b>Rec:</b> - Da definire<br/>
              </Typo>

              <Typo type="h0" color="#ffffff" align="center">Iscrizione</Typo>
              <Typo type="p" color="#ffffff" align="center">Tramite Whatsapp<br/>al n° <a href="https://wa.me/393461330641" target="_blank" rel="noreferrer">+39 346 1330641</a></Typo>

              <Typo type="h0" color="#ffffff" align="center">Impegno</Typo>
              <Typo type="note" color="#ffffff" align="center">Questo percorso richiede un impegno indicativamente di 1 volta al mese, per circa 3 ore.</Typo>             
              <Typo type="note" color="#ffffff" align="center">In questo modo, tra un incontro e l'altro avrete il tempo di lavorare con il vostro cavallo per fissare le competenze impostate ad ogni lezione. Avrete quindi la possibilità, all'incontro successivo, di porre domande, affrontare dubbi e difficoltà, e quindi lavorare per risolvere ogni problema.</Typo>             
            </Box>
            <img src="/photos/thumbnails/percorso-escursione-esterna.jpg" alt="Escursione Esterna " width="100%" />
            <Box variant="light">              
              <Typo type="h4" align="center">Prerequisiti per partecipare</Typo>
              <Typo type="note" align="center">Aver compiuto 14 anni (per il codice della strada)</Typo>
              <Typo type="note" align="center">Aver approfondito il proprio percorso personale di Equitazione Etica o di Equinofilia presso il nostro centro o un altro centro affiliato OPPURE aver partecipato al percorso di Escursione Simulata</Typo>
              <Typo type="note" align="center">Fino all'inizio del corso c'è la possibilità di fare lezioni per arrivare ai requisiti minimi richiesti per la partecipazione!</Typo>             
            </Box>      
              
            
          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default PathsOutdoorExcursions;
