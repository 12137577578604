import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.section`
  padding: 25px;


  ${props => props.variant === "white" ? `
    background-color: #ffffff;
    color: #000000;
  ` : ""}

  ${props => props.variant === "light" ? `
    background-color: #EDDDD4;
    color: #000000;
  ` : ""}

  ${props => props.variant === "primary" ? `
    background-color: #5E0B15;
    ` + (props.image ?  
      `background-image: linear-gradient(259deg, #0000002b 18%, #5E0B15 80%), url('`+props.image+`');`
    : null ) + `
    color: #000000;
    background-size: cover, cover;
  ` : ""}

  
`;


const Box = ({ variant="default", image=null, children }) => {
  return (
    <Wrapper variant={variant} image={image}>
      {children}      
    </Wrapper>
  )
}


export default Box
